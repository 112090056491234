import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useModal } from './ModalContext';
import { useDropzone } from 'react-dropzone';
import LanguageDropdown from '../transcribeLanguageDropdown';
import DashboardLanguageDropdown from '../dashboardLanguageDropdown';
import audioIcon from '../../../assets/img/icon/audio-icon.svg';
import videoIcon from '../../../assets/img/icon/video-icon.svg';
import googleDriveSvg from '../../../assets/img/icon/google-drive.svg';
import dropboxSvg from '../../../assets/img/icon/dropbox.svg';
import oneDriveSvg from '../../../assets/img/icon/onedrive.svg';
import youtubeSvg from '../../../assets/img/icon/youtube.svg';
import deleteSvg from '../../../assets/img/icon/delete.svg';
import { showToast } from "../toast.js";
import ServiceDropdown from '../transcribeServiceDropdown';
import googleIcon from '../../../assets/img/icon/google-icon.png';
import subscriptionBilling from '../../../assets/img/billing/subscription.png';
import billingGift from '../../../assets/img/billing/gift.png';
import { useAuth } from '../../../Auth/AuthContext';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { t } from '../translation';
function BootstrapModal() {
    const { modalType, modalData, hideModal } = useModal();
    const { currentUser, login, hashedId, googleLogin } = useAuth();
    const onRename = modalData?.onRename;
    const onDelete = modalData?.onDelete;
    const onMove = modalData?.onMove;
    const onShareTeam = modalData?.onShareTeam;
    const onUploadFile = modalData?.onUploadFile;
    const onTranscribeFile = modalData?.onTranscribeFile;
    const onTranscribeURL = modalData?.onTranscribeURL;
    const onLogout = modalData?.onLogout;
    const onResetPassword = modalData?.onResetPassword;
    const onDeleteAccount = modalData?.onDeleteAccount;
    const onDeleteAccountComment = modalData?.onDeleteAccountComment;
    const onDeleteCompletely = modalData?.onDeleteCompletely;
    const onCancelSubscription = modalData?.onCancelSubscription;
    const onCancelSubscriptionComment = modalData?.onCancelSubscriptionComment;
    const onCancelSubscriptionComplete = modalData?.onCancelSubscriptionComplete;
    const onDeleteFolder = modalData?.onDeleteFolder;
    const onCreateFolder = modalData?.onCreateFolder;
    const onDeleteWorkspace = modalData?.onDeleteWorkspace;
    const onCreateWorkspace = modalData?.onCreateWorkspace;
    const onAddSpeaker = modalData?.onAddSpeaker;
    const onRenameSpeaker = modalData?.onRenameSpeaker;
    const onDownloadTranscript = modalData?.onDownloadTranscript;
    const onEnterpriseInvite = modalData?.onEnterpriseInvite;
    const onEnterpriseRemove = modalData?.onEnterpriseRemove;
    const onRenameTeam = modalData?.onRenameTeam;
    const onRemoveMember = modalData?.onRemoveMember;
    const onInviteTeam = modalData?.onInviteTeam;
    const onLeaveTeam = modalData?.onLeaveTeam;
    const onDeleteTeam = modalData?.onDeleteTeam;
    const onUploadMultipleFiles = modalData?.onUploadMultipleFiles;
    const onTranscribeMultipleFiles = modalData?.onTranscribeMultipleFiles;
    const onLeaveEditor = modalData?.onLeaveEditor;
    const startAndNavigateTranscription = modalData?.startAndNavigateTranscription;
    const orderData = modalData?.orderData;
    const onChangeUserName = modalData?.onChangeUserName;
    const onDisconnectCalendar = modalData?.onDisconnectCalendar;
    const fetchMeetings = modalData?.fetchMeetings;
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedService, setSelectedService] = useState("Standard");
    const [selectedFolder, setSelectedFolder] = useState(null); // To track the selected folder.
    const [isMoveButtonEnabled, setIsMoveButtonEnabled] = useState(false); // To track if the "Move" button is enabled.
    const [selectedTeam, setSelectedTeam] = useState(null); // To track the selected folder.
    const [isShareButtonEnabled, setIsShareButtonEnabled] = useState(false); // To track if the "Move" button is enabled.
    const [size, setSize] = useState(null);
    const inputRef = useRef();
    const [newFileName, setNewFileName] = useState("");
    const [folders, setFolders] = useState([]);
    const [teams, setTeams] = useState([]);
    const [url, setUrl] = useState("");
    const [mailInput, setMailInput] = useState("");
    const [selectedReason, setSelectedReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [commentsValue, setCommentsValue] = useState("");
    const [additionalValue, setAdditionalValue] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteLastStep, setDeleteLastStep] = useState(false);
    const [newSpeaker, setNewSpeaker] = useState("New Speaker");
    const [exportFormat, setExportFormat] = useState('txt');
    const [paragraphSize, setParagraphSize] = useState('1');
    const [includeTimestamps, setIncludeTimestamps] = useState(false);
    const [mergeSegments, setMergeSegments] = useState(false);
    const [includeSpeakerNames, setIncludeSpeakerNames] = useState(false);
    const [singleParagraph, setSingleParagraph] = useState(false);
    const [transcription, setTranscription] = useState(true);
    const [mp3, setMP3] = useState(false);
    const exportDropdown = useRef(null);
    const paragraphSizeDropdown = useRef(null);
    const fileInputRef = useRef(null);
    const [os, setOs] = useState('Other');

    const [files, setFiles] = useState([]);
    const [validFiles, setValidFiles] = useState([]);


    const [centerModal, setCenterModal] = useState(true);

    const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};

    useEffect(() => {
        setSize(null);
        setMailInput("");

        console.log(modalType);
        if ((modalType === "RENAME" || modalType === "CREATE-FOLDER" || modalType === "CREATE-WORKSPACE" || modalType === "ADD-SPEAKER" || modalType === "RENAME-SPEAKER" || modalType === "ADD-ENTERPRISE-MEMBER") && inputRef.current) {
            inputRef.current.focus();
            setNewFileName(modalData.name);
            setNewSpeaker("New Speaker");
        } else if (modalType === "MOVE") {
            setFolders(modalData.folders);
        } else if (modalType === "SHARE-TEAM") {
            setTeams(modalData.teams);
        } else if (modalType === "UPLOAD-FILE" || modalType === "TRANSCRIBE-FILE" || modalType === "TRANSCRIBE-URL" || modalType === "MULTIPLE-UPLOAD" || modalType === "TRANSCRIBE-MULTIPLE-FILES") {
            setSize("xl");
        } else if (modalType === "RESET-PASSWORD") {
            setMailInput(modalData.email);
            inputRef.current.focus();
        } else if (modalType === "DELETE-ACCOUNT-COMMENT") {
            setSelectedReason(modalData.reason);
            setCommentsValue("");
            setAdditionalValue("");
        } else if (modalType === "DELETE-ACCOUNT-VERIFY") {
            setPassword("");
            setVerifyPassword("");
            setPasswordsMatch(false);
            setIsLoading(false);
            setDeleteLastStep(false);
        }
        if (modalType === "RENAME-SPEAKER") {
            setNewSpeaker(modalData.speaker);
        }
        if (modalType === "DOWNLOAD-TRANSCRIPT") {
            setExportFormat('txt');
            setParagraphSize('1');
            setIncludeTimestamps(false);
            setMergeSegments(false);
            setIncludeSpeakerNames(false);
            setSingleParagraph(false);
            setTranscription(true);
            setMP3(false);
        }
        if (modalType === "TRANSCRIBE-URL") {
            if (inputRef)
                inputRef.current.focus();
        }
        if (modalType === "TRANSCRIBE-MULTIPLE-FILES") {
            setFiles(modalData?.files);
        }
        if (modalType === "MULTIPLE-UPLOAD") {
            setFiles([]);
            setValidFiles([]);
        }
        if (modalType === "EDIT-ACCOUNT") {
            inputRef.current.focus();
            setNewFileName(modalData.name);
        }
        if ((modalType === "RENAME" || modalType === "CREATE-FOLDER" || modalType === "CREATE-WORKSPACE" || modalType === "ADD-SPEAKER" || modalType === "RENAME-SPEAKER" || modalType === "ADD-ENTERPRISE-MEMBER" || modalType === "TRANSCRIBE-URL"|| modalType === "RESET-PASSWORD"|| modalType === "EDIT-ACCOUNT") && isMobileDevice()) {
            setCenterModal(false);
        } else {
            setCenterModal(true);
        }

    }, [modalType]);
    const handleFolderClick = (folder) => {
        setSelectedFolder(folder); // Set the clicked folder as the selected one.
        setIsMoveButtonEnabled(true); // Enable the "Move" button.
    };
    const handleTeamClick = (team) => {
        setSelectedTeam(team); // Set the clicked folder as the selected one.
        setIsShareButtonEnabled(true); // Enable the "Move" button.
    }
    const handleModalHide = () => {
        setSelectedFolder(null);  // Reset the selected folder.
        setIsMoveButtonEnabled(false);  // Disable the "Move" button.
        setSelectedTeam(null);  // Reset the selected folder.
        setIsShareButtonEnabled(false);  // Disable the "Move" button.
        hideModal();  // Close the modal.
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (onRename) {
                onRename(modalData.tid, modalData.uid, newFileName);
            } else if (onCreateFolder) {
                onCreateFolder(modalData.uid, newFileName);
            } else if (onCreateWorkspace) {
                onCreateWorkspace(modalData.uid, newFileName);
            } else if (onEnterpriseInvite) {
                onEnterpriseInvite(modalData.enterpriseId, mailInput);
            } else if (onInviteTeam) {
                onInviteTeam(mailInput);
            } else if (onRenameTeam) {
                onRenameTeam(newFileName);
            } if (onChangeUserName) {
                onChangeUserName(newFileName);
            }
            hideModal();
        }
    };
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);

    };
    const handleServiceChange = (service) => {
        setSelectedService(service);
    }
    const [dropzoneKey, setDropzoneKey] = useState(0);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        fileRejections
    } = useDropzone({
        accept: 'audio/*, video/*',
        maxFiles: modalType === "UPLOAD-FILE" ? 1 : null,
        onDrop: acceptedFiles => {
            setAcceptedFiles(acceptedFiles);
        }
    });


    useEffect(() => {
        if (modalType === "UPLOAD-FILE") {
            setAcceptedFiles([]);
        }
    }, [modalType]);
    useEffect(() => {
        // Handling rejected files
        if (fileRejections.length > 0) {
            showToast(t("Only one file can be uploaded at a time"), { type: "error", position: "top-center" });
        }

        // Filtering for valid and invalid files
        const newValidFiles = acceptedFiles.filter(file =>
            file.type.startsWith('audio/') || file.type.startsWith('video/')
        );

        const invalidFiles = acceptedFiles.filter(file =>
            !file.type.startsWith('audio/') && !file.type.startsWith('video/')
        );

        if (invalidFiles.length > 0) {
            showToast(t("File is not a valid audio or video file."), { type: "error", position: "top-center" });
        }

        // Set the valid files
        setValidFiles(newValidFiles);

        // Handle the upload logic
        if (modalType === "UPLOAD-FILE" && newValidFiles.length > 0) {
            newValidFiles.forEach((file) => {
                hideModal();
                onUploadFile(file);
            });
        } else if (modalType === "MULTIPLE-UPLOAD" && newValidFiles.length > 0) {
            onUploadMultipleFiles(newValidFiles);
        }

    }, [fileRejections, acceptedFiles, modalType, onUploadFile, onUploadMultipleFiles, hideModal]);



    const handleURLInput = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue);  // This logs the value to the console. Use it as needed.
        // ... other code or operations based on the inputValue ...
        setUrl(inputValue);
    };
    const handleReasonChange = (e) => {
        setSelectedReason(e.target.value);
        if (e.target.value !== "other") {
            setOtherReason(""); // Clear the otherReason text if another option is selected
        }
    };
    const handleOtherReasonChange = (e) => {
        setOtherReason(e.target.value);
    };
    async function checkPassword() {

        if (password === verifyPassword) {
            // Handle successful password verification
            console.log("passwords match");
            setPasswordsMatch(true);
            setIsLoading(true);
            try {
                await login(currentUser.email, password);
                setIsLoading(false);
                setDeleteLastStep(true);

            } catch (error) {
                console.log(error);
                hideModal();
                showToast(t("Passwords not matched. Please check passwords."), { type: "error" });
            }
        } else {
            // Handle incorrect password
        }
    }

    const [isExport, setIsExport] = useState(false);
    const [isParagraphSize, setIsParagraphSize] = useState(false);
    const handleExportDropdown = () => {
        setIsExport(!isExport);
    };
    const handleParagraphSizeDropdown = () => {
        setIsParagraphSize(!isParagraphSize);
    };
    const formats = {
        "txt": "TXT",
        "clipboard": "Clipboard",
        "word": "Word",
        "srt": "SRT"
    }


    // Click outside to close the dropdown
    useEffect(() => {
        function handleClickOutside(event) {
            if (exportDropdown.current && !exportDropdown.current.contains(event.target)) {
                setIsExport(false);
            }
            if (paragraphSizeDropdown.current && !paragraphSizeDropdown.current.contains(event.target)) {
                setIsParagraphSize(false);
            }

        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup on component unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [exportDropdown, paragraphSizeDropdown]);


    useEffect(() => {
        const platform = window.navigator.platform;

        if (/Mac/i.test(platform)) {
            setOs("macOS");
        } else if (/Win/i.test(platform)) {
            setOs("Windows");
        }

        // ... you could add more conditions for other OSs if needed
    }, []); // Emp

    const handleRemoveFile = (index) => {
        console.log(index);
        const newFiles = [...files];  // Create a new copy of the files array
        console.log(newFiles);
        newFiles.splice(index, 1);
        setFiles(newFiles);
        if (newFiles.length === 0) {
            hideModal();
        }
    };
    const handleFileChange = (e) => {
        const newFiles = [...files, ...e.target.files];
        // Filtering for valid and invalid files
        const newValidFiles = newFiles.filter(file =>
            file.type.startsWith('audio/') || file.type.startsWith('video/')
        );

        const invalidFiles = newFiles.filter(file =>
            !file.type.startsWith('audio/') && !file.type.startsWith('video/')
        );

        if (invalidFiles.length > 0) {
            showToast(t("File is not a valid audio or video file."), { type: "error", position: "top-center" });
        }
        // Handle the upload logic
        if (modalType === "UPLOAD-FILE" && newValidFiles.length > 0) {
            newValidFiles.forEach((file) => {
                hideModal();
                onUploadFile(file);
            });
        } else if (modalType === "MULTIPLE-UPLOAD" && newValidFiles.length > 0) {
            onUploadMultipleFiles(newValidFiles);
        }
        setFiles(newFiles);  // Assuming you have a state called 'files' to store the list of files
    }

    const googleSignIn = async () => {
        localStorage.setItem("google-clicked", true)
        const response = await googleLogin();
        setIsLoading(false);
        setDeleteLastStep(true);

    };
    const handleFileDrop = (file) => {
        const event = {
            target: {
                files: [file]
            }
        };
        handleFileChange(event);
    };
    const renderModalContent = () => {
        switch (modalType) {
            case 'RENAME':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={() => hideModal()}></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Rename")}</h2>
                            </div>
                            <div className='form-group me-3'>
                                <label >{t("Rename")}</label>
                            </div>
                            <input
                                ref={inputRef}
                                value={newFileName}
                                type='text'
                                onChange={(e) => setNewFileName(e.target.value)}
                                onKeyDown={handleKeyDown}  // <-- add this line

                                className="form-control round-input"
                            />
                            <div className='text-center'>
                                <button className="btn theme-btn mt-3" onClick={() => {
                                    if (onRename) {
                                        onRename(modalData.tid, modalData.uid, newFileName);
                                    }
                                    if (onRenameTeam) {
                                        onRenameTeam(newFileName)
                                    }
                                    hideModal();
                                }}>{t("Rename")}
                                </button>
                            </div>

                        </Modal.Body>



                    </>
                );
            case 'DELETE':
                return (
                    <>

                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                                <p class="ft-18-title mb-4">{t("Are you sure delete this file? It will be deleted permanently...")}</p>
                            </div>
                            <div class="text-center">
                                <button className="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                    if (onDelete) {
                                        if (!modalData.tid) {
                                            onDelete(modalData.uid);
                                        } else {
                                            onDelete(modalData.tid, modalData.uid);

                                        }
                                    }
                                    hideModal();
                                }}>{t("Delete")}
                                </button>

                            </div>

                        </Modal.Body>




                    </>
                );
            case 'MOVE':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Choose Folder")}</h2>
                            </div>
                            {folders && folders.map(folder => (
                                <div
                                    className={`choose-list ${selectedFolder === folder ? 'active' : ''}`}
                                    onClick={() => handleFolderClick(folder)}
                                >
                                    <span class="me-2 theme-color">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder2" viewBox="0 0 16 16">
                                            <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z" />
                                        </svg>
                                    </span>
                                    {folder.Fname.S}
                                </div>
                            ))}
                            <div className='text-center'>
                                <button className="btn theme-btn white-border-btn mt-3 me-2" onClick={() => {
                                    hideModal();
                                    onCreateFolder();

                                }}>{t("Add New Folder")}</button>
                                <button className="btn theme-btn mt-3" style={{
                                    opacity: !isMoveButtonEnabled ? 0.5 : 1  // Change 0.5 to the desired opacity for the disabled state
                                }} onClick={!isMoveButtonEnabled ?
                                    null :

                                    () => {
                                        if (onMove) {
                                            console.log(modalData);
                                            console.log("kek")
                                            if (modalData.tid)
                                                onMove(modalData.tid, modalData.uid, selectedFolder?.SK.S);
                                            else {
                                                onMove(modalData.uid, selectedFolder?.SK.S)
                                            }
                                        }
                                        hideModal();
                                    }}>{t("Move")}
                                </button>
                            </div>

                        </Modal.Body>

                    </>
                );
            case 'SHARE-TEAM':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Choose Workspace")}</h2>
                            </div>
                            {teams && teams.length > 0 ? (
                                teams.map(team => (
                                    <div
                                        className={`choose-list ${selectedTeam === team ? 'active' : ''}`}
                                        onClick={() => handleTeamClick(team)}
                                    >
                                        <span class="me-2 theme-color">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                            </svg>
                                        </span>
                                        {team.TeamName}
                                    </div>
                                ))) : (
                                <div class="text-center">
                                    <p>{t("There is no team yet, please create a team to colloborate with your friends and co-workers by sharing files")}</p>
                                </div>
                            )}
                            <div className='text-center'>
                                <button className="btn theme-btn white-border-btn mt-3 me-2" onClick={() => {
                                    onCreateWorkspace();

                                }}>{t("Add New Workspace")}</button>
                                <button className="btn theme-btn mt-3" style={{
                                    opacity: !isShareButtonEnabled ? 0.5 : 1  // Change 0.5 to the desired opacity for the disabled state
                                }} onClick={
                                    !isShareButtonEnabled ?
                                        null :
                                        () => {

                                            if (onShareTeam) {
                                                if (modalData.tid)
                                                    onShareTeam(modalData.tid, modalData.uid, selectedTeam?.TeamId);
                                                else {
                                                    onShareTeam(modalData.uid, selectedTeam?.TeamId)
                                                }
                                            }
                                            hideModal();
                                        }}>{t("Move")}
                                </button>
                            </div>

                        </Modal.Body>

                    </>
                );
            case 'UPLOAD-FILE':
                return (
                    <>
                        <Modal.Body className='p-0' >

                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div className='row gx-0'>
                                <div className='col-lg-7 order-lg-1 order-2'>
                                    <div className='modal-body-padding'>
                                        <h2 class="mini-title text-center">{t("Upload File")}</h2>
                                        <div
                                            className="drag-upload-div my-4"
                                            onClick={() => fileInputRef.current.click()}
                                            onDragOver={(e) => {
                                                e.preventDefault(); // Necessary to allow drop
                                                e.stopPropagation();
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                // Handle the dropped file(s)
                                                if (e.dataTransfer.items) {
                                                    for (let i = 0; i < e.dataTransfer.items.length; i++) {
                                                        if (e.dataTransfer.items[i].kind === 'file') {
                                                            const file = e.dataTransfer.items[i].getAsFile();
                                                            handleFileDrop(file);
                                                        }
                                                    }
                                                } else {
                                                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                                                        handleFileDrop(e.dataTransfer.files[i]);
                                                    }
                                                }
                                            }}
                                        >
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                accept="audio/*,video/*"
                                            />

                                            <span class="d-inline-block theme-color mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                                </svg>
                                            </span>
                                            <p class="theme-color mb-0">{t("Drop File Here")}</p>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn theme-btn mt-3" onClick={() => {
                                                fileInputRef.current.click();
                                            }}>
                                                <span class="btn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </span>{t("Browse")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-body-padding flex-center text-center d-lg-flex d-none">
                                    <h2 class="mini-title">{t("Upload Single File")}</h2>
                                    <div class="img-icon-list">
                                        <span><img src={audioIcon} class="img-fluid" alt="img" /></span>
                                        <span><img src={videoIcon} class="img-fluid" alt="img" /></span>
                                    </div>
                                    <p>{t("Transcribe your audio or video files from your computer")}</p>
                                </div>
                            </div>
                        </Modal.Body>

                    </>
                );
            case "MULTIPLE-UPLOAD":
                return (
                    <Modal.Body className='p-0' >
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div className='row gx-0'>
                            <div className='col-lg-7 order-lg-1 order-2'>
                                <div className='modal-body-padding'>
                                    <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                                    <div className="drag-upload-div my-4" onClick={() => fileInputRef.current.click()} >
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            multiple
                                            onChange={handleFileChange}
                                            accept="audio/*,video/*"  // This ensures only audio or video types are accepted

                                        />

                                        <span class="d-inline-block theme-color mb-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                            </svg>
                                        </span>
                                        <p class="theme-color mb-0">{t("Drop File Here")}</p>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn theme-btn mt-3" onClick={() => {
                                            fileInputRef.current.click();
                                        }}>
                                            <span class="btn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                </svg>
                                            </span>{t("Browse")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-body-padding flex-center text-center d-lg-flex d-none">
                                <h2 class="mini-title">{t("Upload Files")}</h2>
                                <div class="img-icon-list">
                                    <span><img src={audioIcon} class="img-fluid" alt="img" /></span>
                                    <span><img src={videoIcon} class="img-fluid" alt="img" /></span>
                                </div>
                                <p>{t("Transcribe your audio or video files from your computer")}</p>
                            </div>
                        </div>
                    </Modal.Body>
                );
            case 'TRANSCRIBE-FILE':
                return (
                    <>
                        <Modal.Body className='p-0' >
                            <div className='row gx-0'>
                                <div class="col-lg-7 order-lg-1 order-2">
                                    <div class="modal-body-padding">
                                        <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                                        <div class="form-group input-icon-div">
                                            <label>{t("Uploaded File")}</label>
                                            <input type="text" class="form-control round-input" name="" value={modalData?.file?.name ? modalData.file.name : 'unknown'} disabled />
                                            <span class="input-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="form-group selectBox">
                                            <label>{t("Language")}</label>
                                            <LanguageDropdown onLanguageChange={handleLanguageChange} />

                                        </div>
                                        <div class="form-group selectBox">
                                            <label>{t("Service")}</label>
                                            <ServiceDropdown onServiceChange={handleServiceChange} />
                                        </div>
                                        <div class="text-center">
                                            <button class="btn theme-btn mt-3" onClick={() => {
                                                if (onTranscribeFile) {
                                                    hideModal();

                                                    onTranscribeFile(selectedLanguage, selectedService, modalData?.file);
                                                }
                                            }}>{t("Transcribe")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                                    <h2 class="mini-title">{t("Upload Single File")}</h2>
                                    <div class="img-icon-list">
                                        <span><img src={audioIcon} class="img-fluid" alt="img" /></span>
                                        <span><img src={videoIcon} class="img-fluid" alt="img" /></span>
                                    </div>
                                    <p>{t("Transcribe your audio or video files from your computer")}</p>
                                </div>
                            </div>

                        </Modal.Body>

                    </>
                );
            case "TRANSCRIBE-MULTIPLE-FILES":
                return (
                    <>
                        <Modal.Body className='p-0' >
                            <div className='row gx-0'>
                                <div class="col-lg-7 order-lg-1 order-2">
                                    <div class="modal-body-padding">
                                        <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                                        <div class="form-group input-icon-div">
                                            <label>{t("Uploaded File")}</label>
                                            <div style={{ maxHeight: "400px", overflow: "auto" }}>
                                                {
                                                    files.map((file, index) => (
                                                        <div key={index} className="file-input-wrapper d-flex ">
                                                            <input
                                                                type="text"
                                                                className="form-control round-input m-2"
                                                                value={file.name || 'unknown'}
                                                                disabled
                                                            />
                                                            <button
                                                                className=" btn delete-file-btn m-2 border-none"
                                                                onClick={() => handleRemoveFile(index)}>
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                multiple  // This allows selecting multiple files
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                accept="audio/*,video/*"  // This ensures only audio or video types are accepted

                                            />
                                            <div class="text-center">
                                                <button
                                                    class="btn theme-btn mt-3"
                                                    onClick={() => fileInputRef.current.click()}>
                                                    {t("Add more")}
                                                </button>
                                            </div>

                                        </div>
                                        <div class="form-group selectBox">
                                            <label>{t("Language")}</label>
                                            <LanguageDropdown onLanguageChange={handleLanguageChange} />

                                        </div>
                                        <div class="form-group selectBox">
                                            <label>{t("Service")}</label>
                                            <ServiceDropdown onServiceChange={handleServiceChange} />
                                        </div>
                                        <div class="text-center">
                                            <button class="btn theme-btn mt-3" onClick={() => {
                                                if (onTranscribeMultipleFiles) {
                                                    onTranscribeMultipleFiles(selectedLanguage, selectedService, files);
                                                }
                                                hideModal();
                                            }}>{t("Transcribe")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                                    <h2 class="mini-title">{t("Upload Single File")}</h2>
                                    <div class="img-icon-list">
                                        <span><img src={audioIcon} class="img-fluid" alt="img" /></span>
                                        <span><img src={videoIcon} class="img-fluid" alt="img" /></span>
                                    </div>
                                    <p>{t("Transcribe your audio or video files from your computer")}</p>
                                </div>
                            </div>

                        </Modal.Body>

                    </>
                );
            case 'TRANSCRIBE-URL':
                return (
                    <>
                        <Modal.Body className='p-0' >
                            <div className='row gx-0'>
                                <div class="col-lg-7 order-lg-1 order-2">
                                    <div class="modal-body-padding">
                                        <h2 class="mini-title text-center">{t("Transcribe your URL")}</h2>
                                        <p class="text-center">{t("Please make sure that the url is valid, share file link from Youtube, Google Drive, Dropbox, OneDrive or raw media files...")}</p>
                                        <div class="form-group">
                                            <label>{t("File URL")}</label>
                                            <input type="text" class="form-control round-input" name="" ref={inputRef} placeholder={t("Enter media file URL here")} onChange={handleURLInput} />
                                        </div>

                                        <div class="form-group selectBox">
                                            <label>{t("Language")}</label>
                                            <LanguageDropdown onLanguageChange={handleLanguageChange} />

                                        </div>
                                        <div class="form-group selectBox">
                                            <label>{t("Service")}</label>
                                            <ServiceDropdown onServiceChange={handleServiceChange} />
                                        </div>
                                        <div class="text-center">
                                            <button class="btn theme-btn mt-3" onClick={() => {
                                                if (onTranscribeURL) {
                                                    onTranscribeURL(selectedLanguage, selectedService, url);
                                                }
                                                hideModal();
                                            }}>{t("Transcribe")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                                    <h2 class="mini-title">{t("Convert Audio from Youtube or Cloud")}</h2>
                                    <div class="img-icon-list">
                                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Dropbox"><img src={dropboxSvg} class="img-fluid" alt="img" /></span>
                                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Drive"><img src={googleDriveSvg} class="img-fluid" alt="img" /></span>
                                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="OneDrive"><img src={oneDriveSvg} class="img-fluid" alt="img" /></span>
                                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={youtubeSvg} class="img-fluid" alt="img" /></span>
                                    </div>
                                    <p>{t("Transkriptor will transcribe the audio or video from youtube link or cloud storage (Google Drive, One Drive, Dropbox).")}</p>
                                </div>
                            </div>

                        </Modal.Body>

                    </>
                );
            case 'LOGOUT':
                return (
                    <>

                        <Modal.Body className='text-center'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>

                            <h2 class="mini-title mb-4">Meetingtor</h2>
                            <p>{t("Are you sure you want to sign out from your account? You can sign in anytime...")}</p>
                            <div class="text-center">
                                <button class="btn theme-btn red-bg mt-3" onClick={() => {
                                    if (onLogout) {
                                        onLogout();
                                    }
                                    hideModal();
                                }}>{t("Sign Out")}</button>
                            </div>


                        </Modal.Body>




                    </>
                );
            case 'RESET-PASSWORD':
                return (
                    <>

                        <Modal.Body className='text-center'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>

                            <h2 class="mini-title mb-2">{t("Reset Password")}</h2>
                            <p class="mb-4">{t("Please type your mail address to reset your password")}</p>
                            <div class="form-group">
                                <label>{t("Email Address")}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name=""
                                    ref={inputRef}
                                    value={mailInput}
                                    onChange={(e) => {
                                        setMailInput(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            if (onResetPassword) {
                                                onResetPassword(mailInput);
                                            }
                                            hideModal();
                                        }
                                    }}
                                />

                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3" onClick={() => {
                                    if (onResetPassword) {
                                        onResetPassword(mailInput);
                                    }
                                    hideModal();
                                }}>{t("Reset Password")}</button>
                            </div>


                        </Modal.Body>




                    </>
                );
            case 'DELETE-ACCOUNT':
                return (
                    <>

                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="ft-18-title medium mb-2">{t("We're sorry to see you go")}</h2>
                                <p class="mb-4">{t("We'd really appreciate it if you told us why you want to delete your account. Your insights can help us improve Meetingtor.")}</p>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="missing-features" onChange={handleReasonChange} />{t("Missing features")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="poor-quality" onChange={handleReasonChange} />{t("Poor quality")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="too-expensive" onChange={handleReasonChange} />{t("Too expensive")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="no-longer-needed" onChange={handleReasonChange} />{t("I no longer need Meetingtor")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" value="other" name="r1" onChange={handleReasonChange} checked={selectedReason === "other"} />{t("Other")}</label>
                            </div>
                            {selectedReason === "other" && (
                                <div class="form-group mt-3">
                                    <label>{t("Please specify")}</label>
                                    <textarea
                                        rows="4"
                                        class="form-control round-input"
                                        value={otherReason}
                                        onChange={handleOtherReasonChange}
                                        placeholder=""
                                    ></textarea>
                                </div>
                            )}
                            <div class="text-center">
                                <button
                                    class="btn theme-btn mt-3"
                                    disabled={selectedReason === "other" ? otherReason.trim() === "" : selectedReason === ""}
                                    onClick={() => {
                                        hideModal();
                                        if (onDeleteAccount) {
                                            onDeleteAccount(selectedReason, otherReason);
                                        }
                                    }}
                                >
                                    {t("Continue")}
                                </button>
                            </div>


                        </Modal.Body>




                    </>
                );
            case 'DELETE-ACCOUNT-COMMENT':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            {selectedReason === "missing-features" ? (
                                <>
                                    <div class="form-group">
                                        <label>{t("Which features would you like to see added?")}*</label>
                                        <textarea rows="4" class="form-control" name="" value={commentsValue}
                                            onChange={(e) => setCommentsValue(e.target.value)}
                                        ></textarea>
                                    </div>
                                </>
                            ) : <>
                                <div class="form-group">
                                    <label>{t("What was the problem you encountered?")}*</label>
                                    <textarea rows="4" class="form-control" name="" value={commentsValue}
                                        onChange={(e) => setCommentsValue(e.target.value)}

                                    >

                                    </textarea>
                                </div>

                            </>}
                            <div class="form-group">
                                <label>{t("Would you like to share any additional thoughts with us? (optional)")}</label>
                                <textarea rows="4" class="form-control" name="" value={additionalValue}
                                    onChange={(e) => setAdditionalValue(e.target.value)}
                                ></textarea>
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    disabled={!commentsValue} // disabled if both are empty
                                    onClick={() => {
                                        hideModal();
                                        if (onDeleteAccountComment) {
                                            onDeleteAccountComment(modalData.reason, commentsValue, additionalValue);
                                        }
                                    }}
                                >{t("Continue")}</button>
                            </div>

                        </Modal.Body>
                    </>
                );
            case 'DELETE-ACCOUNT-VERIFY':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            {!deleteLastStep ? (
                                <>
                                    <div class="text-center">
                                        <h2 class="mini-title mb-2">{t("Verification")}</h2>
                                        <p class="mb-4">{t("To continue delete your account, verify you are the owner of this account by your password or logging into your associated Google account.")}</p>
                                        <button onClick={googleSignIn} class="btn theme-btn w-100 white-border-btn mb-3"><span class="btn-icon"><img src={googleIcon} class="img-fluid" alt="img" /></span>{t("Sign In with Google")}</button>
                                        <h2 class="ft-18-title medium my-3">{t("OR")}</h2>
                                    </div>
                                    <div class="form-group">
                                        <label>{t("Enter your password")}</label>
                                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>

                                            <input
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                                placeholder={t("Enter your password")}
                                            />
                                            {(passwordsMatch && verifyPassword.length > 0) ?
                                                <span style={{ color: 'green', marginLeft: '10px' }}>✓</span> :
                                                (verifyPassword.length > 0 ?
                                                    <span style={{ color: 'red', marginLeft: '10px' }}>✗</span> : null)
                                            }
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{t("Verify your password")}</label>
                                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="password"
                                                className="form-control"
                                                style={{ flexGrow: 1 }} // ensures the input takes up most of the space
                                                value={verifyPassword}
                                                onChange={(e) => {
                                                    setVerifyPassword(e.target.value);
                                                    setPasswordsMatch(e.target.value === password);
                                                }}
                                                placeholder={t("Verify your password")}
                                            />
                                            {(passwordsMatch && verifyPassword.length > 0) ?
                                                <span style={{ color: 'green', marginLeft: '10px' }}>✓</span> :
                                                (verifyPassword.length > 0 ?
                                                    <span style={{ color: 'red', marginLeft: '10px' }}>✗</span> : null)
                                            }
                                        </div>

                                    </div>
                                    <div className='row' style={{ display: isLoading ? "block" : "none" }}>
                                        <div className='d-flex justify-content-center'>
                                            <div className="spinner-border text-primary" role="status">

                                            </div>
                                        </div>


                                    </div>
                                    <div class="text-center">
                                        <button class="btn theme-btn mt-3"
                                            disabled={!passwordsMatch}
                                            onClick={checkPassword}

                                        >{t("Continue")}</button>

                                    </div>
                                </>
                            ) : (
                                <>
                                    <div class="text-center">
                                        <h2 class="mini-title mb-2">{t("Delete Account")}</h2>
                                        <h5 class="mb-2">{t("Delete account forever?")}</h5>
                                        <p class="mb-4">{t("That includes your profile, all conversations, imported recordings, and everything you've done since joining.")}</p>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn theme-btn red-btn-bg mt-3"
                                            onClick={() => {
                                                hideModal();
                                                if (onDeleteCompletely) {
                                                    onDeleteCompletely(modalData?.reason, modalData?.comment, modalData?.additional);
                                                }
                                            }}
                                        >{t("Delete")}</button>

                                    </div>
                                </>

                            )}

                        </Modal.Body>
                    </>
                );
            // Add more modal types as needed...
            case 'CANCEL-SUBSCRIPTION':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="ft-18-title medium mb-2">{t("We're sorry to see you go")}</h2>
                                <p class="mb-4">{t("We'd really appreciate if you told us why you want to cancel your subscription. Your insights can help us improve Meetingtor")}</p>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="missing-features" onChange={handleReasonChange} />{t("Missing features")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="poor-quality" onChange={handleReasonChange} />{t("Poor quality")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="too-expensive" onChange={handleReasonChange} />{t("Too expensive")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" name="r1" value="no-longer-needed" onChange={handleReasonChange} />{t("I no longer need Meetingtor")}</label>
                            </div>
                            <div class="radio custom mb-2">
                                <label><input type="radio" value="other" name="r1" onChange={handleReasonChange} checked={selectedReason === "other"} />{t("Other")}</label>
                            </div>
                            {selectedReason === "other" && (
                                <div class="form-group mt-3">
                                    <label>{t("Please specify")}</label>
                                    <textarea
                                        rows="4"
                                        class="form-control round-input"
                                        value={otherReason}
                                        onChange={handleOtherReasonChange}
                                        placeholder=""
                                    ></textarea>
                                </div>
                            )}
                            <div class="text-center">
                                <button
                                    class="btn theme-btn mt-3"
                                    disabled={selectedReason === "other" ? otherReason.trim() === "" : selectedReason === ""}
                                    onClick={() => {
                                        hideModal();
                                        if (onCancelSubscription) {
                                            onCancelSubscription(selectedReason, otherReason);
                                        }
                                    }}
                                >
                                    {t("Continue")}
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                );
            case 'CANCEL-SUBSCRIPTION-COMMENT':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            {selectedReason === "missing-features" ? (
                                <>
                                    <div class="form-group">
                                        <label>{t("Which features would you like to see added?")}*</label>
                                        <textarea rows="4" class="form-control" name="" value={commentsValue}
                                            onChange={(e) => setCommentsValue(e.target.value)}
                                        ></textarea>
                                    </div>
                                </>
                            ) : <>
                                <div class="form-group">
                                    <label>{t("What was the problem you encountered?")}*</label>
                                    <textarea rows="4" class="form-control" name="" value={commentsValue}
                                        onChange={(e) => setCommentsValue(e.target.value)}

                                    >

                                    </textarea>
                                </div>

                            </>}
                            <div class="form-group">
                                <label>{t("Would you like to share any additional thoughts with us? (optional)")}</label>
                                <textarea rows="4" class="form-control" name="" value={additionalValue}
                                    onChange={(e) => setAdditionalValue(e.target.value)}
                                ></textarea>
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    disabled={!commentsValue} // disabled if both are empty
                                    onClick={() => {
                                        hideModal();
                                        if (onCancelSubscriptionComment) {
                                            onCancelSubscriptionComment(modalData.reason, commentsValue, additionalValue);
                                        }
                                    }}
                                >{t("Continue")}</button>
                            </div>
                        </Modal.Body>
                    </>
                );
            case 'CANCEL-SUBSCRIPTION-COMPLETE':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            {modalData?.isRewarded ? (
                                <>

                                    <div className='text-center'>
                                        <p class="small-title">{t("Are you sure you want to cancel your subscription?")}</p>

                                    </div>
                                    <div class="text-center">
                                        <button class="btn theme-btn red-btn-bg m-3"
                                            onClick={() => {
                                                hideModal();
                                                if (onCancelSubscriptionComplete) {
                                                    onCancelSubscriptionComplete(modalData?.reason, modalData?.comment, modalData?.additional, modalData?.isRewarded);
                                                }
                                            }}
                                        >{t("Cancel Subscription")}</button>
                                        <button class="btn theme-btn m-3"
                                            onClick={() => {
                                                hideModal();
                                            }}
                                        >{t("Keep Subscription")}</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div class="row align-items-center">
                                        <div class="col-lg-5 text-center">
                                            <img src={billingGift} class="img-fluid" alt="img" />
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="text-center py-lg-4 py-2">
                                                <p class="ft-18-title mb-4">{t("It is sad to see you go. To thank you for your feedback, Here is free gift:")}</p>
                                                <p>{t("Click the Get 1 Month Free button to continue your subscription for 1 month free")}</p>
                                                <div class="mt-lg-5 mt-4">
                                                    <button class="btn theme-btn transparent-white-btn mt-1 me-1"
                                                        onClick={() => {
                                                            hideModal();
                                                            if (onCancelSubscriptionComplete) {
                                                                onCancelSubscriptionComplete(modalData?.reason, modalData?.comment, modalData?.additional, true);
                                                            }
                                                        }}
                                                    >{t("Continue to Cancel")}</button>
                                                    <button class="btn theme-btn white-border-btn theme-color mt-1"
                                                        onClick={() => {
                                                            hideModal();
                                                            if (onCancelSubscriptionComplete) {
                                                                onCancelSubscriptionComplete(modalData?.reason, modalData?.comment, modalData?.additional, modalData?.isRewarded);
                                                            }
                                                        }}
                                                    >{t("Get one month free")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Modal.Body>
                    </>
                );
            case 'CANCEL-SUBSCRIPTION-MINUTES':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="row align-items-center">
                                <div class="col-lg-12 text-center">
                                    <img src={subscriptionBilling} class="img-fluid" alt="img" />
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-center pt-3">
                                        <p class="small-title">{t("We have gifted you 120 minutes that you can use anytime in the future!")}</p>
                                        <p>{t("When you need to convert your next conversation to text. Remember that Transkriptor is here for you and it will be\u2026 Always ;)")}</p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                );
            case "CANCEL-SUBSCRIPTION-GIFT":
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="row align-items-center">
                                <div class="col-lg-12 text-center">
                                    <img src={billingGift} class="img-fluid" alt="img" />
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-center py-3">
                                        <p class="small-title">{t("We have gifted you 1 Month Free Subscription so you can continue transcribing audio to text!")}</p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                );
            case 'DELETE-FOLDER':
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                                <p class="ft-18-title mb-4">{t("Are you sure to delete this folder? All transcriptions inside the folder will be deleted?")}</p>
                            </div>
                            <div class="text-center">
                                <button className="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                    if (onDeleteFolder) {
                                        onDeleteFolder(modalData.fid, modalData.uid);
                                    }
                                    hideModal();
                                }}>{t("Delete")}
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                );
                case 'DISCONNECT-CALENDAR':
                    return (
                        <>
                            <Modal.Body>
                                <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                <div class="text-center">
                                    <h2 class="mini-title text-center mb-4">{t("Disconnect")}</h2>
                                    <p class="ft-18-title mb-4">{t("Are you sure you want to disconnect your calendar? Please be aware that by doing so, you will no longer be able to record meetings associated with this account.")}</p>
                                </div>
                                <div class="text-center">
                                    <button className="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                        if (onDisconnectCalendar) {
                                            onDisconnectCalendar(modalData.calendarId);
                                        }
                                        hideModal();
                                    }}>{t("Disconnect")}
                                    </button>
                                </div>
                            </Modal.Body>
                        </>
                    );
            case "CREATE-FOLDER":
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4"> {t("Create Folder")}</h2>
                            </div>
                            <div class="form-group me-3">
                                <label>{t("Create Folder")}</label>
                                <input
                                    ref={inputRef}
                                    type='text'
                                    onChange={(e) => setNewFileName(e.target.value)}
                                    onKeyDown={handleKeyDown}  // <-- add this line

                                    className="form-control round-input"
                                    placeholder={t("Enter folder name")}
                                />
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    onClick={() => {
                                        if (onCreateFolder) {
                                            onCreateFolder(modalData.uid, newFileName);
                                        }
                                        hideModal();
                                    }}
                                >{t("Create")}</button>
                            </div>
                        </Modal.Body>
                    </>
                );
            case 'DELETE-WORKSPACE':
                return (
                    <>

                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                                <p class="ft-18-title mb-4">{t("Are you sure delete this workspace? It will be deleted permanently with all the files and folders inside...")}</p>
                            </div>
                            <div class="text-center">
                                <button className="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                    if (onDeleteWorkspace) {
                                        onDeleteWorkspace(modalData.tid, modalData.uid);
                                    }
                                    hideModal();
                                }}>{t("Delete")}
                                </button>

                            </div>

                        </Modal.Body>
                    </>
                );
            case "CREATE-WORKSPACE":
                return (
                    <>
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4"> {t("Create Workspace")}</h2>
                            </div>
                            <div class="form-group me-3">
                                <label>{t("Create Workspace")}</label>
                                <input
                                    ref={inputRef}
                                    type='text'
                                    onChange={(e) => setNewFileName(e.target.value)}
                                    onKeyDown={handleKeyDown}  // <-- add this line

                                    className="form-control round-input"
                                    placeholder={t("Enter workspace name")}
                                />
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    onClick={() => {
                                        if (onCreateWorkspace) {
                                            onCreateWorkspace(modalData.uid, newFileName);
                                        }
                                        hideModal();
                                    }}
                                >{t("Create")}</button>
                            </div>
                        </Modal.Body>
                    </>
                );
            case "SHORTCODES":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center dark-color">
                                <h2 class="mini-title text-center mb-4">{t("Shortcodes")}</h2>
                                {os === "Windows" ? (
                                    <div class="row text-start shortcodes-p">
                                        <div class="col-5">
                                            <p class="nowrap">Ctrl + S</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Save")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Tab</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Next Paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Alt + Up</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Previous Paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">{t("Delete")}</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge with next")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">{t("Backspace")}</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge with previous")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Enter</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Split paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Ctrl + Space</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Play/Pause Audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Alt + Left</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Rewind 5 seconds")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Alt + Right</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Skip 5 seconds")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">CTRL + 9</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Speed up audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">CTRL + 0</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Slow down audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">CTRL  + 1, 2, 3, 4</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Change Person")}</p>
                                        </div>

                                        <div class="col-5">
                                            <p class="nowrap">CTRL + 5</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Select Person")}</p>
                                        </div>

                                        <div class="col-5">
                                            <p class="nowrap">CTRL + M</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge Same Speakers")}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div class="row text-start shortcodes-p">
                                        <div class="col-5">
                                            <p class="nowrap">control + S</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Save")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Tab</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Next Paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">option + Up</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Previous Paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Delete</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge with next")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Backspace</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge with previous")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">Enter</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Split paragraph")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">option + Space</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Play/Pause Audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">option + Left</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Rewind 5 seconds")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">option + Right</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Skip 5 seconds")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">control + 9</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Speed up audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">control + 0</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Slow down audio")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">control  + 1, 2, 3, 4</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Change Person")}</p>
                                        </div>
                                        <div class="col-5">
                                            <p class="nowrap">control + 5</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Select Person")}</p>
                                        </div>

                                        <div class="col-5">
                                            <p class="nowrap">control + M</p>
                                        </div>
                                        <div class="col-7">
                                            <p>{t("Merge Same Speakers")}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                );
            case "ADD-SPEAKER":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Add Speaker")}</h2>
                            </div>
                            <div class="form-group me-3">
                                <label>{t("New Speaker")}</label>
                                <input type="text" class="form-control round-input" ref={inputRef} name="" placeholder={t("New Speaker")} value={newSpeaker} onChange={(e) => setNewSpeaker(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (onAddSpeaker) {
                                            hideModal();
                                            onAddSpeaker(e.target.value);
                                        }
                                    }
                                }} />
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    onClick={(e) => {
                                        if (onAddSpeaker) {
                                            hideModal();
                                            onAddSpeaker(newSpeaker);
                                        }
                                    }}
                                >{t("Add")}</button>
                            </div>
                        </div>
                    </Modal.Body>
                );
            case "RENAME-SPEAKER":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Rename Speaker")}</h2>
                            </div>
                            <div class="form-group me-3">
                                <label>{t("Rename Speaker")}</label>
                                <input type="text" class="form-control round-input" ref={inputRef} name="" placeholder={t("Rename Speaker")} value={t(newSpeaker)} onChange={(e) => setNewSpeaker(e.target.value)} onKeyDown={(e) => {

                                    if (e.key === 'Enter') {
                                        if (onRenameSpeaker) {
                                            hideModal();
                                            onRenameSpeaker(modalData.speaker, e.target.value);
                                        }
                                    }
                                }} />
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3"
                                    onClick={(e) => {
                                        if (onRenameSpeaker) {
                                            hideModal();
                                            onRenameSpeaker(modalData.speaker, newSpeaker);
                                        }
                                    }}
                                >{t("Add")}</button>
                            </div>
                        </div>

                    </Modal.Body>
                );
            case "DOWNLOAD-TRANSCRIPT":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">{t("Download Text")}</h2>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group selectBox" ref={exportDropdown}>
                                        <label>{t("Export format")}</label>
                                        <div class="form-control round-input selectBox__value" onClick={handleExportDropdown}>{formats[exportFormat]}</div>
                                        {isExport && (
                                            <div class="dropdown-menu" onClick={handleExportDropdown}>
                                                <a href="#" class="dropdown-item" data-value="txt" onClick={(e) => setExportFormat(e.target.getAttribute('data-value'))}>TXT</a>
                                                <a href="#" class="dropdown-item" data-value="clipboard" onClick={(e) => setExportFormat(e.target.getAttribute('data-value'))}>Clipboard</a>
                                                <a href="#" class="dropdown-item" data-value="word" onClick={(e) => setExportFormat(e.target.getAttribute('data-value'))}>Word</a>
                                                <a href="#" class="dropdown-item" data-value="srt" onClick={(e) => setExportFormat(e.target.getAttribute('data-value'))}>SRT</a>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group selectBox" ref={paragraphSizeDropdown}>
                                        <label>{t("Paragraph Size")}</label>
                                        <div class="form-control round-input selectBox__value" onClick={handleParagraphSizeDropdown}>{paragraphSize} {t("line")}</div>
                                        {isParagraphSize && (

                                            <div class="dropdown-menu" onClick={handleParagraphSizeDropdown}>
                                                <a href="#" class="dropdown-item" data-value="1" onClick={(e) => setParagraphSize(e.target.getAttribute('data-value'))}>1 {t("line")}</a>
                                                <a href="#" class="dropdown-item" data-value="2" onClick={(e) => setParagraphSize(e.target.getAttribute('data-value'))}>2 {t("lines")}</a>
                                                <a href="#" class="dropdown-item" data-value="4" onClick={(e) => setParagraphSize(e.target.getAttribute('data-value'))}>4 {t("lines")}</a>
                                                <a href="#" class="dropdown-item" data-value="8" onClick={(e) => setParagraphSize(e.target.getAttribute('data-value'))}>8 {t("lines")}</a>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" checked={includeTimestamps} onClick={(e) => setIncludeTimestamps(!includeTimestamps)} />{t("Include timestamps")}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" onClick={(e) => setMergeSegments(!mergeSegments)} />{t("Merge same-speaker segments")}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" onClick={(e) => setIncludeSpeakerNames(!includeSpeakerNames)} />{t("Include speaker names")}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" onClick={(e) => setSingleParagraph(!singleParagraph)} />{t("Single paragraph")}</label>
                                    </div>
                                </div>
                                <div class="col-12 mb-4"></div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" checked={transcription} onClick={(e) => setTranscription(!transcription)} />{t("Transcription")}</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="checkbox custom mb-2">
                                        <label><input type="checkbox" onClick={(e) => setMP3(!mp3)} />MP3</label>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button class="btn theme-btn mt-3" disabled={!transcription && !mp3 ? true : false} onClick={() => {
                                    if (onDownloadTranscript) {
                                        hideModal();
                                        onDownloadTranscript(exportFormat, paragraphSize, includeTimestamps, mergeSegments, includeSpeakerNames, singleParagraph, transcription, mp3);
                                    }
                                }}>{t("Download")}</button>
                            </div>
                        </div>
                    </Modal.Body>
                );
            case "UPGRADE-DOWNLOAD":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                <p>{t("Downloading the meeting recording is available in paid accounts")}</p>
                            </div>
                            <div class="text-center">
                                <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Get started")}</Link>
                            </div>
                        </div>
                    </Modal.Body>
                );
                case "UPGRADE-TRANSCRIBE":
                    return (
                        <Modal.Body>
                            <div className='notranslate'>
                                <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                <div class="text-center">
                                    <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                    <p>{t("The transcription feature is available in paid accounts")}</p>
                                </div>
                                <div class="text-center">
                                    <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Upgrade")}</Link>
                                </div>
                            </div>
                        </Modal.Body>
                    );
                case "UPGRADE-FOLDER":
                    return (
                        <Modal.Body>
                            <div className='notranslate'>
                                <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                <div class="text-center">
                                    <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                    <p>{t("The ability to create folders is available in paid accounts")}</p>
                                </div>
                                <div class="text-center">
                                    <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Upgrade")}</Link>
                                </div>
                            </div>
                        </Modal.Body>
                    );
                    case "UPGRADE-WORKSPACE":
                    return (
                        <Modal.Body>
                            <div className='notranslate'>
                                <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                <div class="text-center">
                                    <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                    <p>{t("The ability to create workspaces is available in paid accounts")}</p>
                                </div>
                                <div class="text-center">
                                    <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Upgrade")}</Link>
                                </div>
                            </div>
                        </Modal.Body>
                    );
                    case "UPGRADE-RECORD":
                        return (
                            <Modal.Body>
                                <div className='notranslate'>
                                    <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                    <div class="text-center">
                                        <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                        <p>{t("You've run out of file credit. Please upgrade your account to record this meeting.")}</p>
                                    </div>
                                    <div class="text-center">
                                        <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Upgrade")}</Link>
                                    </div>
                                </div>
                            </Modal.Body>
                        );
                        case "UPGRADE-CONNECT":
                            return (
                                <Modal.Body>
                                    <div className='notranslate'>
                                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                                        <div class="text-center">
                                            <h2 class="mini-title text-center mb-4">Meetingtor</h2>
                                            <p>{t("You've run out of file credit. Please upgrade your account to link your calendar.")}</p>
                                        </div>
                                        <div class="text-center">
                                            <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Upgrade")}</Link>
                                        </div>
                                    </div>
                                </Modal.Body>
                            );
                       
                    
            case "UPGRADE-COPY":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                                <p>{t("Transcript copy is available only in paid accounts")}</p>
                            </div>
                            <div class="text-center">
                                <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Get started")}</Link>
                            </div>
                        </div>
                    </Modal.Body>
                );
            case "UPGRADE-SAVE":
                return (
                    <Modal.Body>
                        <div className='notranslate'>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <div class="text-center">
                                <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                                <p>{t("Full transcript saving is available only in paid accounts")}</p>
                            </div>
                            <div class="text-center">
                                <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Get started")}</Link>
                            </div>
                        </div>
                    </Modal.Body>
                );
            case "UPGRADE-MINUTES":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                            <p>{t("Upgrade to get more minutes and transcribe more files!")}</p>
                        </div>
                        <div class="text-center">
                            <Link to="/payment" target='_blank' class="btn theme-btn mt-3">{t("Get started")}</Link>
                        </div>
                    </Modal.Body>
                );
            case "ADD-ENTERPRISE-MEMBER":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-2">{t("Invite a friend")}</h2>
                            <p class="mb-4">{t("Invite team member or friend to collaborate with same files and folders, and share files with them.")}</p>
                        </div>
                        <div class="form-group me-3">
                            <label>{t("Email address")}</label>
                            <input type="text" class="form-control round-input" ref={inputRef} value={mailInput} onChange={(e) => {
                                setMailInput(e.target.value);
                            }}
                                onKeyDown={handleKeyDown}
                                name="" placeholder="Email address" />
                        </div>
                        <div class="text-center">
                            <button class="btn theme-btn mt-3" onClick={() => {
                                if (onEnterpriseInvite) {
                                    onEnterpriseInvite(modalData.enterpriseId, mailInput);
                                } else if (onInviteTeam) {
                                    onInviteTeam(mailInput);
                                }
                            }}>{t("Send invitation email")}</button>
                        </div>
                    </Modal.Body>
                );
            case "REMOVE-ENTERPRISE-MEMBER":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("Remove")}</h2>
                            <p class="ft-18-title mb-4">{t("Are you sure to remove this team member or friend?")}</p>
                        </div>
                        <div class="text-center">
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onEnterpriseRemove) {
                                    hideModal();
                                    onEnterpriseRemove(modalData.mail);
                                } else if (onRemoveMember) {
                                    hideModal();
                                    onRemoveMember(modalData.email, modalData?.tid);
                                }
                            }}>{t("Remove")}</button>
                        </div>
                    </Modal.Body>
                );
            case "SHOW-TEAM-MEMBERS":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("Workspace Members")}</h2>
                        </div>
                        <ul className='dark-color'>
                            {modalData?.members?.map((member, index) => (
                                <li key={index}>
                                    <p class="d-flex justify-content-between align-items-center mb-1">
                                        {modalData?.ownerMail === member ? (
                                            <>
                                                <span>{member} &nbsp; ({t("Admin")})</span>

                                            </>
                                        ) : (
                                            <>
                                                <span>{member} &nbsp; ({t("Team")})</span>
                                                {modalData?.ownerId === hashedId && (<Link onClick={() => onRemoveMember(member, modalData?.tid)}>
                                                    <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                                                        <img src={deleteSvg} class="img-fluid" alt="img" />
                                                    </span>
                                                </Link>)}

                                            </>
                                        )}

                                    </p>
                                </li>
                            ))}

                        </ul>

                    </Modal.Body>
                );
            case "DELETE-TEAM":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                            <p class="ft-18-title mb-4">{t("Are you sure to delete this workspace? All files, folders, workspace members inside the workspace will be deleted...")}</p>
                        </div>
                        <div class="text-center">
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onDeleteTeam) {
                                    hideModal();
                                    onDeleteTeam();
                                }
                            }}>{t("Delete")}</button>
                        </div>
                    </Modal.Body>
                );
            case "LEAVE-TEAM":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("Leave")}</h2>
                            <p class="ft-18-title mb-4">{t("Are you sure to leave from this workspace? All files, folders, workspace members inside the workspace will be unaccesible...")}</p>

                        </div>
                        <div class="text-center">
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onLeaveTeam) {
                                    hideModal();
                                    onLeaveTeam();
                                }
                            }}>{t("Leave")}</button>
                        </div>
                    </Modal.Body>
                );
            case "LANGUAGE-MODAL":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <h2 class="mini-title text-center mb-4">{t("Choose Language")}</h2>
                        <div class="form-group selectBox">
                            <label>{t("Transcription Language")}</label>
                            <LanguageDropdown onLanguageChange={handleLanguageChange} />

                        </div>
                        <div class="form-group selectBox">
                            <label>{t("Dashboard Language")}</label>
                            <DashboardLanguageDropdown onLanguageChange={handleLanguageChange} />

                        </div>
                    </Modal.Body>
                );


                case "TRANSCRIPTION-LANGUAGE-MODAL":
                    return (
                        <Modal.Body>
                            <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                            <h2 class="mini-title text-center mb-4">{t("Choose Language")}</h2>
                            <p class="ft-18-title mb-4">{t("Please choose the language of the meeting to begin transcription.")}</p>

                            <div class="form-group selectBox">
                                <label>{t("Transcription Language")}</label>
                                <LanguageDropdown onLanguageChange={handleLanguageChange} />
    
                            </div>
                            <div className='text-center'>
                            <button class="btn theme-btn  mt-3" onClick={() => {
                                if (startAndNavigateTranscription) {
                                    hideModal();
                                    startAndNavigateTranscription(orderData);
                                }
                            }}>{t("Continue")}</button> 
                            </div>
                        </Modal.Body> 
                    );

            case "CONFIRM-EDITOR":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <h2 class="mini-title text-center mb-4">{t("Leave")} </h2>
                        <div className='text-center'>
                            <p>{t("Please make sure you saved the changes before leaving the page!")}</p>
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onLeaveEditor) {
                                    hideModal();
                                    onLeaveEditor(modalData.link);
                                }
                            }}>{t("Leave")}</button>
                        </div>
                    </Modal.Body>
                );
            case "CONFIRM-UPLOADER":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <h2 class="mini-title text-center mb-4">{t("Attention")}</h2>
                        <div className='text-center'>
                            <p>{t("Please wait until your file is uploaded to server!")}</p>
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onLeaveEditor) {
                                    hideModal();
                                    onLeaveEditor(modalData.link);
                                }
                            }}>{t("Leave Anyway")}</button>
                        </div>
                    </Modal.Body>
                );
            case "CONFIRM-RECORDER":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <h2 class="mini-title text-center mb-4">{t("Attention")}</h2>
                        <div className='text-center'>
                            <p>{t("Please wait until your recording is finished!")}</p>
                            <button class="btn theme-btn red-btn-bg mt-3" onClick={() => {
                                if (onLeaveEditor) {
                                    hideModal();
                                    onLeaveEditor(modalData.link);
                                }
                            }}>{t("Leave Anyway")}</button>
                        </div>
                    </Modal.Body>
                );
            case "EDIT-ACCOUNT":
                return (
                    <Modal.Body>
                        <button type="button" class="btn-close" onClick={hideModal} aria-label="Close"></button>
                        <h2 class="mini-title text-center mb-4">{t("Edit Account")}</h2>
                        <div className='text-center'>
                            <div class="form-group me-3">
                                <label>{t("Name")}</label>
                                <input type="text" class="form-control round-input" ref={inputRef} value={newFileName} onChange={(e) => {
                                    setNewFileName(e.target.value);
                                }}
                                    onKeyDown={handleKeyDown}
                                    name="" placeholder={t("Name")} />
                            </div>
                            <button class="btn theme-btn  mt-3" onClick={() => {
                                if (onChangeUserName) {
                                    hideModal();
                                    onChangeUserName(modalData.link);
                                }
                            }}>{t("Save")}</button>
                        </div>
                    </Modal.Body>
                );
            default:
                return null;
        }
    };

    return (
        <Modal show={!!modalType} onHide={handleModalHide} centered={centerModal} size={size} className="notranslate" >
            {renderModalContent()}
        </Modal>
    );
}

export default BootstrapModal;
