import SidebarMenu from "./menu";
import DynamicButtons from './button';
import LogoDetails from "./logo";
import { useState, useRef, useEffect } from "react";
function Sidebar({ isOpen, handleCloseSidebar, isBlocked }) {

  const sidebarRef = useRef();
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleCloseSidebar();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div className={`sidebar-div-box notranslate ${isOpen ? "show-sidebar" : ""}`} ref={sidebarRef}>
      <div className="sidebar-menu-close" onClick={handleCloseSidebar} style={{ display: isOpen ? "block" : "" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <LogoDetails isBlocked={isBlocked} />
      <SidebarMenu isBlocked={isBlocked} handleCloseSidebar={handleCloseSidebar} />

    </div>
  );
}

export default Sidebar;