// FileItem.js
import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {useModal} from '../../components/common/Modal/ModalContext'
import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'
import viewSvg from '../../assets/img/icon/view.svg'
import {t} from '../../components/common/translation'
import { useNavigate } from 'react-router-dom';

function WorkspaceItem({ workspace, uid, onDelete, onRename, openRemoveDialog}) {
    
    const ownerId = workspace?.OwnerHashedId;
    const ownerMail = workspace?.OwnerEmail;
    const tid = workspace?.TeamId;
    const name = workspace?.TeamName;
    const [members, setMembers] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { showModal } = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        get_members();
    }, [workspace]);

    const get_members = async () => {
            fetch("https://w31p7ivup6.execute-api.eu-central-1.amazonaws.com/default/Get-Team-Member-Mails-v2-website?TeamId=" + tid).then(res => res.json()).then((result) => {
                setMembers(result?.Members);
            }).catch((error) => {});
    };

    useEffect(() => {
        function handleDocumentClick(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);
    
    return (
        <tr>
            <td>
				<span class="theme-color">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                    </svg>
				</span>
			</td>
            <td class="w-100 dark-color regular pointer" onClick={() => navigate(`/workspace/${tid}`)}>
                <Link to={`/workspace/${tid}`}>
                {name}

                </Link>
                </td>
            <td className='text-end'>
                <div className='action-icon-list res-action-list'>
                    <Link onClick={() => showModal('RENAME', { tid: tid, uid: uid, name: name }, { onRename })}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                            <img src={renameSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                    <Link onClick={()=> showModal("SHOW-TEAM-MEMBERS", {members, ownerMail, ownerId, tid}, {onRemoveMember: openRemoveDialog})}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("View")}>
							<img src={viewSvg} class="img-fluid" alt="img"/>
						</span>
                    </Link>
                    <Link onClick={() => showModal('DELETE-WORKSPACE', { tid: tid, uid: uid }, { onDeleteWorkspace: onDelete })}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Delete")}>
                            <img src={deleteSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                </div>
                <div class="dropdown dropstart res-action-dropdown" >
					<button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
							<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
						</svg>
					</button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<li><Link class="dropdown-item"  onClick={() => showModal('RENAME', { tid: tid, uid: uid, name: name }, { onRename })}>
						<span class="action-drop-icon">
							<img src={renameSvg} class="img-fluid" alt="img"/>
						</span>{t("Rename")}
						</Link></li>
                        <li><Link class="dropdown-item"  onClick={()=> showModal("SHOW-TEAM-MEMBERS", {members, ownerMail, ownerId, tid}, {onRemoveMember: openRemoveDialog})}>
						<span class="action-drop-icon">
							<img src={viewSvg} class="img-fluid" alt="img"/>
						</span>{t("View")}
						</Link></li>
						<li><Link class="dropdown-item" onClick={() => showModal('DELETE-WORKSPACE', { tid: tid, uid: uid }, { onDeleteWorkspace: onDelete })}>
								<span class="action-drop-icon">
								<img src={deleteSvg} class="img-fluid" alt="img"/>
							</span>{t("Delete")}
						</Link></li>
					</ul>
				</div>
            </td>
         
        </tr>
    );
}

export default WorkspaceItem;