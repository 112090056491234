import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../Auth/AuthContext';
import { fetchData, fetchDataPost } from '../../hooks/apiUtils';
import FileItem from './FileItem';
import { useApiData } from '../../hooks/useApiData';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moveWhite from '../../assets/img/icon/move-white.svg';
import shareWhite from '../../assets/img/icon/share-white.svg';
import deleteWhite from '../../assets/img/icon/delete-white.svg';
import checkAllWhite from '../../assets/img/icon/check-all-white.svg';
import sharePeopleSvg from '../../assets/img/icon/share-people.svg';
import sharePeopleSvg2 from '../../assets/img/icon/share-people2.svg';

import closeSvg from '../../assets/img/icon/close.svg';
import { showToast } from '../../components/common/toast';
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"
import renameSvg from "../../assets/img/icon/rename.svg"
import deleteSvg from "../../assets/img/icon/delete.svg"
import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import botPng from "../../assets/img/icon/minute-btn-icon.svg"
import { useModal } from '../../components/common/Modal/ModalContext';

import { t } from "../../components/common/translation";
import { Modal } from 'react-bootstrap';

function Files(props) {
    const { fid, uid, tid } = useParams();
    const { userData, hashedId } = useAuth();

    const { showModal } = useModal();
    const [userStatus, setUserStatus] = useState(null);

    const navigate = useNavigate();
    const [allFiles, setAllFiles] = useState([]);
    const [filesToShow, setFilesToShow] = useState([]);
    const [folders, setFolders] = useState([]);
    const [teams, setTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filesLength, setFilesLength] = useState(0);
    const [checkedItems, setCheckedItems] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [folderName, setFolderName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [processingMeetLoading, setProcessingMeetLoading] = useState(true);
    const isFile = props.isFile || false;
    const isFolder = props.isFolder || false;
    const filesPerPage = isFile ? 10 : 4;

    var startIndex = 0;
    var endIndex = 0;
    const fetchFiles = async () => {
        setIsLoading(true); // Set loading to true at the beginning of data fetching

        var url = "https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + hashedId + "&app=Meetingtor";
        if (fid !== undefined) {
            url = " https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + fid + "&app=Meetingtor";
        }
        if (tid !== undefined) {
            var url = "https://0koqxthb4m.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFiles?cid=" + tid + "&app=Meetingtor";

        }

        const data = await fetchData(url);
        console.log(data);
        data.sort((a, b) => {
            return parseInt(b.Date?.N) - parseInt(a.Date?.N);
        });
        setAllFiles(data);
        setFilesLength(data.length);
        setIsLoading(false); // Set loading to false once data fetching is complete
        return true;
    }

    const onProcessingFileClicked = async (tid) => {
        setShowProcessingModal(true);
        const res = await fetchFiles();
        if (res) {
            // check whether the file is still processing
            if (fileInProgress(allFiles.filter(file => file?.SK?.S === "#Meetingtor#" + tid)[0]?.TStatus?.S)) {
                setProcessingMeetLoading(false);
            } else {
                setProcessingMeetLoading(false);
                setShowProcessingModal(false);
                navigate(`/meeting/${tid}`);
            }
        }

    }

    useEffect(() => {
        if (userData)
            setUserStatus(userData.SStatus);
    }, [userData]);


    const fetchFolders = async () => {
        let tempId = tid ? tid : hashedId;
        const data = await fetchData("https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + tempId);
        if (fid !== undefined) {
            data.forEach(element => {
                if (element.SK.S.replace("User#", "") === fid) {
                    setFolderName(element.Fname.S)
                }
            });
        }
        setFolders(data);
    };
    const fetchTeams = async () => {
        const data = await fetchData("https://5eozpazgv4.execute-api.eu-central-1.amazonaws.com/default/Get-Teams?HashedId=" + hashedId)
        setTeams(data)
    };
    useEffect(() => {
        console.log(hashedId);
        console.log("in use effect")
        if (hashedId || tid || fid) {
            fetchFiles();
            fetchFolders();
            fetchTeams();
        }

    }, [hashedId]);

    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(allFiles.slice(startIndex, endIndex));

    }, [allFiles, currentPage]);
    useEffect(() => {
        console.log("Checked Items Updated:", checkedItems[0]);
    }, [checkedItems]);
    useEffect(() => {
        if (filesToShow.length > 0) {
            for (let file of filesToShow) {
                let date_flag = false;
                let myDate = new Date(parseInt(file?.Date?.N)) || Date.now(); // assuming file.Date.N is a Unix timestamp in milliseconds
                let currentDate = Date.now();
                let diffInMs = currentDate - myDate.getTime();

                if (diffInMs < (1000 * 60 * 60 * 24)) {
                    date_flag = true;
                }

                let status = file?.TStatus?.S || "Completed";
                const delayInMilliseconds = 120000;

                if (fileInProgress(status) && date_flag) {

                    setTimeout(fetchFiles, delayInMilliseconds);
                    break; // This will exit the loop if the conditions are met
                }
            }
        }
    }, [filesToShow]);

    const handleDelete = (tid, uid) => {
        // Implement delete functionality here
        // After deletion from the server, remove it from state
        fetchDataPost("https://2dwvlsoy9a.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFile", { tid: "#Meetingtor#" + tid, cid: uid }).then(data => {
            console.log(data)
            console.log(tid);
            console.log(uid);
            fetchFiles();
            showToast(t("File deleted"), { type: "success" });

        });
    }

    const handleRename = (tid, uid, name) => {
        console.log(tid);
        console.log(uid);
        console.log(name);
        fetchDataPost("https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?", { tid: "#Meetingtor#" + tid, cid: "User#" + uid, Tname: name }).then(data => {
            console.log(data)
            console.log(tid);
            console.log(uid);
            fetchFiles();
            showToast(t("File renamed"), { type: "success" });

        });


        // Implement rename functionality here
        // After renaming on the server, update the name in state
    }

    const handleMove = (tid, uid, fid) => {
        // Implement move functionality here
        // Update state accordingly
        fetchDataPost("https://319zoan27h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFile", { pk: "User#" + uid, tid: "#Meetingtor#" + tid, fid: fid }).then(data => {
            console.log(data)
            console.log(tid);
            console.log(uid);
            fetchFiles();
            showToast(t("File moved to folder"), { type: "success" });

        });
    }
    const handleShareTeam = (tid, uid, teamId) => {
        console.log(tid);
        console.log(uid);
        console.log(teamId);
        fetchDataPost(
            "https://po0s8vut8h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFileToTeam?",
            { pk: "User#" + uid, sk: "#Meetingtor#" + tid, TeamId: teamId }).then(data => {
                fetchFiles();
                showToast(t("File moved to team"), { type: "success" });

            });
    }

    const fileInProgress = (file) => {
        return file === "Processing" || file === "in_call_recording" || file === "in_call_not_recording";
    };
    const handleCheckboxChange = (fileId, isChecked) => {
        setCheckedItems(prevState => {
            // Step 1: Create a temporary copy of the checkedItems state
            const tempArray = { ...prevState };

            // Step 2: Modify the temporary array
            tempArray[fileId] = isChecked;

            // Get checked items from the updated temporary array
            const checkedIds = Object.keys(tempArray).filter(id => tempArray[id]);

            // Do something if the length of checked items is more than 0
            if (checkedIds.length > 0) {
                // Your logic here
                console.log('One or more items are checked!');
            }

            // Step 3: Return the modified temporary array to update the state
            return tempArray;
        });
    };

    const handleShare = async (oid) => {
        console.log(oid);
        try {
            let link = "https://app.meetingtor.com/sharing/" + oid;
            await navigator.clipboard.writeText(link);

            showToast(t("File link copied to clipboard"));

            return true;

        } catch (err) {
            console.error('Failed to copy text: ', err);
            return false;
        }

    };
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        console.log(e.target.value);
        filterFiles(e.target.value);

    }

    const filterFiles = (query) => {
        const filtered = allFiles.filter(file =>
            file?.TempName?.S.toLowerCase().includes(query.toLowerCase())
        );
        console.log(filtered);
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;
        setFilteredFiles(filtered);
        setFilesToShow(filtered.slice(startIndex, endIndex));
    }
    const onDelete = async (fid, uid) => {
        console.log(fid, uid);

        const response = await fetchDataPost("https://5ex4m1gaw1.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFolder?cid=" + uid + "&tid=" + fid);
        showToast(t("Folder deleted"));
        navigate("/folders")
    };
    const onRename = async (fid, uid, name) => {
        console.log(name, fid, uid);

        const response = await fetchDataPost("https://xc8v5q5vc2.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFolder",
            { "tid": fid, "Tname": name, "cid": uid });
        setFolderName(name);
        showToast(t("Folder renamed"));
    };

    const onMoveMultiple = async (uid, fid) => {
        console.log(fid, uid);
        console.log("kek");
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleMove(fileId.replace("#Meetingtor#", ""), uid, fid);
            }
        }

    };

    const onDeleteMultiple = async (uid) => {
        console.log("kek");
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleDelete(fileId.replace("#Meetingtor#", ""), uid);
            }
        }
    };

    const selectAll = () => {
        const allChecked = {};

        filesToShow.forEach(file => {
            allChecked[file?.SK?.S] = true;
        });

        setCheckedItems(allChecked);
    };

    const unselectAll = () => {
        const allChecked = {};



        setCheckedItems(allChecked);
    };

    const onShareMultiple = async (uid, tid) => {
        console.log("kek");
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleShareTeam(fileId.replace("#Meetingtor#", ""), uid, tid);
            }
        }
    };

    const onCreateFolder = async () => {
        if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-FOLDER");
        } else {

            showModal('CREATE-FOLDER', { uid: hashedId }, { onCreateFolder: handleCreateFolder });
        }
    }
    const handleCreateFolder = async (uid, folderName) => {
        const response = await fetchDataPost("https://lq3fr34z20.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreateFolder",
            { Fname: folderName, cid: uid });
        fetchFolders();
        showToast(t("Folder created"));
    };
    const onCreateWorkspace = async () => {
        if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-WORKSPACE");
        } else {
            showModal("CREATE-WORKSPACE", { uid: hashedId }, { onCreateWorkspace: handleCreateWorkspace })
        }
    };
    const handleCreateWorkspace = async (uid, workspaceName) => {
        const response = await fetchDataPost("https://0n4xaw0876.execute-api.eu-central-1.amazonaws.com/default/Create-Team",
            { UserHashedId: uid, TeamName: workspaceName });
        fetchTeams();
        showToast(t("Workspace created"));
    };
    return (
        <>
            <>
                <Modal show={showProcessingModal} onHide={() => setShowProcessingModal(false)} centered>
                    <Modal.Body >
                        <button type="button" class="btn-close" onClick={() => setShowProcessingModal(false)} aria-label="Close"></button>
                        <div class="text-center">
                            <h2 class="mini-title text-center mb-4">{t("Meeting in progress")}</h2>
                            <p class="ft-18-title mb-4">{t("This meeting is still in progress. Please check after all the meeting participants have left the meeting.")}</p>
                        </div>
                        <div class="text-center">
                            {processingMeetLoading ? (
                                <div className="spinner-border text-primary" role="status"> </div>
                            ) : (
                                <></>)}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
            <div className='row'>
                <div className="col-12" style={{ display: !isFile ? "none" : "" }}>
                    <div className='d-lg-flex d-block justify-content-between align-items-center'>
                        {!isFolder ? (
                            <h2 className="card-title">{t("Files")}</h2>
                        ) : (<>
                            <h2 class="card-title w-100"><Link to="/folders" class="hover-underline">{t("Folders")}</Link> / {folderName}
                                <div class="action-icon-list d-sm-inline-flex d-flex justify-content-end">
                                    <Link onClick={() => showModal('RENAME', { tid: fid, uid: hashedId, name: folderName }, { onRename })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                            <img src={renameSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                    <Link onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: hashedId }, { onDeleteFolder: onDelete })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={("Delete")}>
                                            <img src={deleteSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                            </h2>
                        </>)}
                        <div className='w-100 text-end d-lg-block d-none'>
                            {!fid && Object.keys(checkedItems).filter(id => checkedItems[id]).length > 0 && (
                                <>
                                    <button class="btn small-table-btn green-btn-bg me-1 mb-1" onClick={() => showModal('MOVE', { folders: folders, uid: hashedId }, { onMove: onMoveMultiple, onCreateFolder: onCreateFolder })}>
                                        <span class="btn-icon me-2"><img src={moveWhite} class="img-fluid" alt="img" /></span>{t("Move")}
                                    </button>
                                    <button className='btn small-table-btn theme-btn-bg me-1 mb-1' onClick={() => showModal('SHARE-TEAM', { teams: teams, uid: hashedId }, { onShareTeam: onShareMultiple, onCreateWorkspace: onCreateWorkspace })}>
                                        <span className='btn-icon me-2'><img src={sharePeopleSvg2} className="img-fluid" alt="img" /></span>{t("Add to a workspace")}
                                    </button>
                                    <button class="btn small-table-btn red-btn-bg  me-1 mb-1" onClick={() => showModal('DELETE', { uid: hashedId }, { onDelete: onDeleteMultiple })} >
                                        <span class="btn-icon me-2"><img src={deleteWhite} class="img-fluid" alt="img" /></span>{t("Delete")}
                                    </button>
                                    <button class="btn small-table-btn theme-btn-bg me-1 mb-1" onClick={() => selectAll()} style={{ display: Object.keys(checkedItems).filter(id => checkedItems[id]).length === filesToShow.length ? "none" : "" }}>
                                        <span class="btn-icon me-2"><img src={checkAllWhite} class="img-fluid" alt="img" /></span>{t("Select All")}
                                    </button>
                                    <button class="btn small-table-btn me-1 mb-1 red-btn-bg" onClick={() => unselectAll()} style={{ display: Object.keys(checkedItems).filter(id => checkedItems[id]).length === filesToShow.length ? "" : "none" }}>
                                        <span class="btn-icon me-2">x</span>{t("Unselect All")}
                                    </button>
                                </>
                            )}
                            <div class="mb-4 table-serach input-icon-div d-lg-inline-block d-block ms-auto mt-lg-0 mt-3">
                                <input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={searchQuery} onChange={handleInputChange} />
                                <span class="input-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className={`${isFile ? "card card-padding" : ""}`}>
                        <div className='table-responsive'>
                            {isLoading ? (
                                <div className='d-flex justify-content-center text-center'>
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                filesToShow.length === 0 ? (
                                    <div className='d-flex justify-content-center text-center'>
                                        <div className='card no-file-card text-center'>
                                            <div className='card-body'>
                                                <div className='text-center'>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <p style={{ "lineHeight": 1 }}>{t("No meetings recorded")}</p>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <table className='table'>
                                        <tbody>
                                            {filesToShow.map((file, index) => (
                                                <FileItem
                                                    key={file?.SK?.S}
                                                    hideOptions={props.hideOptions}
                                                    file={file}
                                                    index={index + ((currentPage - 1) * filesPerPage)}
                                                    onDelete={handleDelete}
                                                    onRename={handleRename}
                                                    onMove={handleMove}
                                                    onShare={handleShare}
                                                    onShareTeam={handleShareTeam}
                                                    folders={folders}
                                                    teams={teams}
                                                    isChecked={checkedItems[file?.SK?.S] || false}
                                                    onCheckboxChange={handleCheckboxChange}
                                                    isFile={isFile}
                                                    onProcessing={fetchFiles}
                                                    isFolder={isFolder}
                                                    onCreateFolder={onCreateFolder}
                                                    onCreateWorkspace={onCreateWorkspace}
                                                    onProcessingFileClicked={onProcessingFileClicked}
                                                />
                                            ))}

                                            {Array.from({ length: filesPerPage - filesToShow.length }).map((_, index) => (
                                                <tr key={index} style={{ height: "51.07px" }}>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                )
                            )}

                        </div>

                        <nav aria-label="..." className={`${filesToShow.length === 0 ? 'd-none' : ''}`} >
                            <ul className='pagination pagination-sm justify-content-end'>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                                        <span className="fa fa-angle-left"></span>
                                    </Link>
                                </li>

                                {/* Show previous page if it's not the first page */}
                                {currentPage > 1 && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
                                            {currentPage - 1}
                                        </Link>
                                    </li>
                                )}

                                {/* Current Page */}
                                <li className="page-item active">
                                    <Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
                                        {currentPage}
                                    </Link>
                                </li>

                                {/* Show next page if it's not the last page */}
                                {currentPage < Math.ceil(filesLength / filesPerPage) && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
                                            {currentPage + 1}
                                        </Link>
                                    </li>
                                )}

                                <li className={`page-item ${currentPage === Math.ceil(filesLength / filesPerPage) ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(filesLength / filesPerPage)))}>
                                        <span className="fa fa-angle-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Files;
