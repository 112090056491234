import React, { useEffect, useState } from 'react';
import { useApiData } from '../hooks/useApiData';
import { useAuth } from '../Auth/AuthContext';

import profilePng from "../assets/img/profile.png"
import profileRewardSvg from "../assets/img/icon/profile-reward.svg"
import profileRewardWhiteSvg from "../assets/img/icon/profile-reward-white.svg"
import profileBillingSvg from "../assets/img/icon/profile-billing.svg"
import profileBillingWhiteSvg from "../assets/img/icon/profile-billing-white.svg"
import profileDeleteSvg from "../assets/img/icon/profile-delete.svg"
import profileDeleteWhiteSvg from "../assets/img/icon/profile-delete-white.svg"
import profileHelpSvg from "../assets/img/icon/profile-help.svg"
import profileHelpWhiteSvg from "../assets/img/icon/profile-help-white.svg"
import profilePasswordSvg from "../assets/img/icon/profile-password.svg"
import profilePasswordWhiteSvg from "../assets/img/icon/profile-password-white.svg"
import logoutSvg from "../assets/img/icon/logout.svg"
import { fetchData, fetchDataPost } from '../hooks/apiUtils';
import { showToast } from '../components/common/toast';
import { useModal } from '../components/common/Modal/ModalContext'
import { useNavigate } from 'react-router-dom';
import Gleap from 'gleap';
import { t } from '../components/common/translation';

function Account() {
	const { currentUser, hashedId, logout, sendResetPasswordMail, updateProfileName } = useAuth();
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [apiKey, setApiKey] = useState('');
	const [status, setStatus] = useState('');
	const [photoUrl, setPhotoUrl] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const { showModal } = useModal();

	const getAccountDetails = async () => {
		const data = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
		setEmail(data.Mail);
		setStatus(data.SStatus);
		setApiKey(hashedId);
		setName(currentUser.displayName ? currentUser.displayName : currentUser.email.split('@')[0]);
		setPhotoUrl(currentUser.photoURL ? currentUser.photoURL : profilePng);
		setIsLoading(false);
	};
	useEffect(() => {
		if (hashedId) {
			getAccountDetails();
			console.log(currentUser)
		}

	}, [hashedId]);

	const copyClipboard = async () => {
		try {
			await navigator.clipboard.writeText(apiKey);

			showToast(t("API key copied..."));

			return true;

		} catch (err) {
			console.error('Failed to copy text: ', err);
			return false;
		}
	};
	const onLogout = async () => {
		try {
			await logout();
			// Do something after successful login, like redirecting to another page
		} catch (error) {
			console.error("Failed to login:", error);
			// Handle login errors, for example, show a message to the user
		}
	};
	const onResetPassword = async (mail) => {
		setIsLoading(true);
		await sendResetPasswordMail(mail);
		showToast(t("Password reset mail has been sent..."));
		setIsLoading(false);
	};
	const checkAccount = async () => {
		var response = await fetchData("https://44iypa7tq6.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-ReturnPricingPage-v2?cid=" + hashedId);
		if (response["mycase"] == "NoSub") {
			showModal("DELETE-ACCOUNT", { onDeleteAccount });
		} else {
			showToast(t("You have active subscription please update or cancel your existing subscription on billing page"));
		}
	};
	const onDeleteAccount = (selectedReason, otherReason) => {

		if (selectedReason === "poor-quality" || selectedReason === "missing-features") {
			showModal("DELETE-ACCOUNT-COMMENT", { reason: selectedReason }, { onDeleteAccountComment });
		} else {
			showModal("DELETE-ACCOUNT-VERIFY", { reason: selectedReason, comment: otherReason, additional: "" }, { onDeleteCompletely });
		}
	};
	const onDeleteAccountComment = (reason, comment, additional) => {
		showModal("DELETE-ACCOUNT-VERIFY", { reason: reason, comment: comment, additional: additional }, { onDeleteCompletely });
	};
	const onDeleteCompletely = async (reason, comment, additional) => {

		const data = { cid: hashedId, email: currentUser.email, reason: reason, detail1: comment, detail2: additional, type: "AccountDelete" };

		var response = await fetchData("https://2w94hb6okj.execute-api.eu-central-1.amazonaws.com/default/Delete-User-Permanently-v2?uid=" + hashedId);
		if (response) {
			response = fetchDataPost("https://a426uvhnw8.execute-api.eu-central-1.amazonaws.com/default/Churn-Flow-User-Feedback",
				data)
			showToast(t("Your account has been deleted permanently"), "success");
			onLogout();
		}
	};

	const onChangeUserName = async (name) => {
		const response = await updateProfileName(name);
		getAccountDetails();
		showToast(t("Name updated"), { type: "success" })
	}
	return (
		<>

			<div className='row' style={{ display: isLoading ? "block" : "none" }}>
				<div className='d-flex justify-content-center'>
					<div className="spinner-border text-primary" role="status">

					</div>
				</div>


			</div>
			<div class="row" style={{ display: isLoading ? "none" : "" }}>
				<div className='col-lg-4 col-12 card-margin'>
					<div className='card card-padding card-100'>
						<div className='text-center'>
							<img src={photoUrl} class="img-fluid account-profile" alt="icon" />
							<p class="ft-18-title dark-color mb-0">{name}</p>
							<p class="light-color mb-0">{t(status)}</p>
						</div>
						<hr class="my-4"></hr>
						<div>
							<div className='profile-list'>
								<div className='profile-list-title'>
									<p>
										<span className='theme-color me-2'>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
												<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
											</svg>
										</span>{t("Name")}
									</p>
								</div>
								<p class="profile-list-desc">{name}</p>
							</div>
							<div className='profile-list'>
								<div className='profile-list-title'>
									<p>
										<span class="theme-color me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
												<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
											</svg>
										</span>{t("Email")}
									</p>
								</div>
								<p class="profile-list-desc">{email}</p>
							</div>
							<div className='profile-list'>
								<div className='profile-list-title'>
									<p>
										<span class="theme-color me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
												<path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
												<path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
											</svg>
										</span>{t("API Key")}
									</p>
								</div>
								<div class="profile-list-desc-div">
									<p class="profile-list-desc">{apiKey}</p>
									<span class="desc-copy-icon theme-color ms-2 pointer" onClick={() => copyClipboard()}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19.01 19.01">
											<g id="copy" transform="translate(-2.25 -2.25)">
												<path id="Path_245" data-name="Path 245" d="M22.578,10.358V22.578H10.358V10.358H22.578m0-1.358H10.358A1.358,1.358,0,0,0,9,10.358V22.578a1.358,1.358,0,0,0,1.358,1.358H22.578a1.358,1.358,0,0,0,1.358-1.358V10.358A1.358,1.358,0,0,0,22.578,9Z" transform="translate(-2.676 -2.676)" fill="currentColor" />
												<path id="Path_246" data-name="Path 246" d="M3.608,13.113H2.25v-9.5A1.358,1.358,0,0,1,3.608,2.25h9.5V3.608h-9.5Z" transform="translate(0 0)" fill="currentColor" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<div class="text-center">
							<button class="btn theme-btn mt-3" onClick={() => showModal("EDIT-ACCOUNT", { name: name }, { onChangeUserName })}>{t("Edit")}
								<span class="ms-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
										<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
										<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
									</svg>
								</span>
							</button>
						</div>
					</div>

				</div>
				<div class="col-lg-8 col-12">
					<div class="row h-100 gx-sm-4 gx-3">
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding card-100 text-center profile-card-box" onClick={() => navigate("/rewards")}>
								<img src={profileRewardSvg} class="img-fluid m-auto mb-sm-4 mb-3" width="60px" alt="img" />
								<p class="ft-18-title mb-sm-3 mb-2">{t("Rewards")}</p>
								<p class="mb-0">{t("Invite Friends and Earn Free Minutes")}</p>
								<div class="profile-card-hover-div">
									<img src={profileRewardWhiteSvg} class="img-fluid mb-4" width="60px" alt="img" />
									<p class="ft-18-title white-color mb-0">{t("View Rewards")}
										<span class="ms-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
											</svg>
										</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding card-100 text-center profile-card-box" onClick={() => navigate('/billing')}>
								<img src={profileBillingSvg} class="img-fluid m-auto mb-sm-4 mb-3" width="60px" alt="img" />
								<p class="ft-18-title mb-sm-3 mb-2">{t("Billing")}</p>
								<p class="mb-0">{t("Upgrade or cancel your subscription.")}</p>
								<div class="profile-card-hover-div green-bg">
									<img src={profileBillingWhiteSvg} class="img-fluid mb-4" width="60px" alt="img" />
									<p class="ft-18-title white-color mb-0">{t("View Bills")}
										<span class="ms-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
											</svg>
										</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding card-100 text-center profile-card-box" onClick={() => Gleap.openHelpCenter()}>
								<img src={profileHelpSvg} class="img-fluid m-auto mb-sm-4 mb-3" width="60px" alt="img" />
								<p class="ft-18-title mb-sm-3 mb-2">{t("Help Center")}</p>
								<p class="mb-0">{t("Frequently asked questions")}</p>
								<div class="profile-card-hover-div purple-bg">
									<img src={profileHelpWhiteSvg} class="img-fluid mb-4" width="60px" alt="img" />
									<p class="ft-18-title white-color mb-0">{t("View Help Center")}
										<span class="ms-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
											</svg>
										</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding card-100 text-center profile-card-box" onClick={() => showModal('RESET-PASSWORD', { email: email }, { onResetPassword })}>
								<img src={profilePasswordSvg} class="img-fluid m-auto mb-sm-4 mb-3" width="60px" alt="img" />
								<p class="ft-18-title mb-sm-3 mb-2">{t("Reset Password")}</p>
								<p class="mb-0">{t("Send mail to reset your password.")}</p>
								<div class="profile-card-hover-div yellow-bg">
									<img src={profilePasswordWhiteSvg} class="img-fluid mb-4" width="60px" alt="img" />
									<p class="ft-18-title white-color mb-0">{t("Change Password")}
										<span class="ms-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
											</svg>
										</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding card-100 text-center profile-card-box" onClick={() => checkAccount()}>
								<img src={profileDeleteSvg} class="img-fluid m-auto mb-sm-4 mb-3" width="60px" alt="img" />
								<p class="ft-18-title mb-sm-3 mb-2">{t("Delete Account")}</p>
								<p class="mb-0">{t("Your profile will be permanently deleted")}</p>
								<div class="profile-card-hover-div red-bg">
									<img src={profileDeleteWhiteSvg} class="img-fluid mb-4" width="60px" alt="img" />
									<p class="ft-18-title white-color mb-0">{t("Delete Account")}
										<span class="ms-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
											</svg>
										</span>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-6 card-margin">
							<div class="card card-padding flex-center logout-box-card pointer card-100 text-center" onClick={() => showModal('LOGOUT', { onLogout })}>
								<div class="logout-icon-div mb-2">
									<div class="logout-icon">
										<img src={logoutSvg} class="img-fluid" width="26px" alt="img" />
									</div>
								</div>
								<p class="ft-18-title mb-0">{t("Sign Out")}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Account;
