import { Modal } from 'react-bootstrap';
import { t } from './translation'
import { useAuth } from '../../Auth/AuthContext';
import { useState, useEffect, useRef } from 'react';
import LanguageDropdown from './transcribeLanguageDropdown';
import { useModal } from './Modal/ModalContext';

function MeetingtorOptionsModal({ showMeetingtorOptionsModal, hideMeetingtorOptionsModal, getAllMeetings, credits }) {
    const [userStatus, setUserStatus] = useState(null);

    const [autoJoinOption, setAutoJoinOption] = useState('all');
    const [shareOption, setShareOption] = useState('me_and_team');
    const [autoJoinBool, setAutoJoinBool] = useState(false);
    const [autoTranscribeBool, setAutoTranscribeBool] = useState(false);
    const [transcriptionLanguage, setTranscriptionLanguage] = useState(null);
    const { showModal } = useModal();


    const [saveInProgress, setSaveInProgress] = useState(false)

    const { currentUser, hashedId, userData } = useAuth();

    const recordingRef = useRef(null);
    const transcriptionRef = useRef(null);

    useEffect(() => {
        if (userData)
          setUserStatus(userData.SStatus);
      }, [userData]);

    useEffect(() => {
        fetchSettings();
        setSaveInProgress(false);
    }, [showMeetingtorOptionsModal, hashedId]);

    const getDomain = () => {
        var domain = ''
        domain = currentUser?.email.split('@')[1];
        return domain;
    }

    const toggleAutoTranscribe = () => {

        if (userStatus && userStatus === "Trial") {
            hideMeetingtorOptionsModal();
            showModal("UPGRADE-TRANSCRIBE");
        } else {
            if (!autoTranscribeBool) {
                if (recordingRef.current && !autoJoinBool) recordingRef.current.click();
            }

            setAutoTranscribeBool(!autoTranscribeBool);
        }
    }

    const toggleRecording = async () => {
        if (!autoJoinBool && credits <= 0 && userStatus && userStatus === "Trial") {
            hideMeetingtorOptionsModal();
            showModal("UPGRADE-RECORD");
        } else {
            if (autoJoinBool) {
                if (transcriptionRef.current && autoTranscribeBool) transcriptionRef.current.click();
            }
            setAutoJoinBool(!autoJoinBool);
        }
    }



    const saveSettings = () => {
        if (saveInProgress) return;
        setSaveInProgress(true);
        fetch(`https://0f4fahvrib.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-SetPreferences?uid=${hashedId}&meetingtor_auto_join=${autoJoinBool}&meetingtor_auto_join_option=${autoJoinOption}&meetingtor_share_settings=${shareOption}&meetingtor_auto_transcribe=${autoTranscribeBool}&meetingtor_auto_transcribe_language=${transcriptionLanguage}`).then(res => res.json()).then((result) => {

            console.log(result);
            
            hideMeetingtorOptionsModal();
            getAllMeetings();
            
           
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchSettings = () => {
        if (!hashedId) return;
        fetch(`https://icdfsu8b3e.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-GetUserPreferences?uid=${hashedId}`).then(res => res.json()).then((result) => {
            setAutoJoinBool(result.settings.meetingtor_auto_join);
            setAutoJoinOption(result.settings.meetingtor_auto_join_option);
            setShareOption(result.settings.meetingtor_share_settings);
            setAutoTranscribeBool(result.settings.meetingtor_auto_transcribe);
            setTranscriptionLanguage(result.settings.meetingtor_auto_transcribe_language);
        }).catch((error) => {
            console.log(error);
        });
    }



    return (
        <Modal
            show={showMeetingtorOptionsModal}
            onHide={hideMeetingtorOptionsModal}
            centered
        >
            <Modal.Body >
                <button type="button" class="btn-close" onClick={hideMeetingtorOptionsModal} aria-label="Close"></button>
                <h2 class="mini-title text-center mb-4">{"Meetingtor " +t("Preferences")}</h2>
                <div class="form-group mb-4">
                    <div className='d-flex justify-content-between mb-1'>
                        <label className="form-label">{t("Auto-join calendar events")}</label>
                        <div className="form-check form-switch" style={{ color: "red" }} id="switchCheckDefault" >
                            <input
                                className="form-check-input pointer"
                                type="checkbox"
                                ref={recordingRef}
                                role="switch"
                                onClick={(e) => toggleRecording()}
                                defaultChecked={autoJoinBool}
                                style={{ backgroundColor: !autoJoinBool ? 'white' : '#059628',borderColor:'#059628' }}
                            />
                        </div>
                    </div>
                    {autoJoinBool ? (
                        <select className="form-select form-control round-input pointer" value={autoJoinOption} onChange={(e) => setAutoJoinOption(e.target.value)}>
                            <option value="all">{t("Join all calendar events with web conference link")}</option>
                            <option value="owned">{t("Join only calendar events that I own")}</option>
                            <option value="internal">{t("Join only internal events with") + ' ' + getDomain() + ' ' + t("teammates")}</option>
                            <option value="external">{t("Join only external events with") + ' non-' + getDomain() + ' ' + t("clients")}</option>
                        </select>
                    ) : (
                        <div className="form-select form-control round-input pointer" style={{ color: '#B4B4B3' }}>
                            {t("Disable all meetings")}
                        </div>
                    )}
                </div>

                <div class="form-group mb-4">
                    <label className="form-label">{t("Share email recap")}</label>
                    <select className="form-select form-control round-input pointer" value={shareOption} onChange={(e) => setShareOption(e.target.value)}>
                        <option value="me_and_team">{t("Send recaps to only me and participants from") + " " + getDomain()}</option>
                        <option value="everyone">{t("Send recaps to everyone on the invite")}</option>
                        <option value="me">{t("Send recaps to only me")}</option>
                    </select>
                </div>
               {/*
                <div class="form-group ">
                
                    <label>{t("Transcription Language")}</label>
                    <LanguageDropdown onLanguageChange={setTranscriptionLanguage} />
                </div>
                 <div className='d-flex justify-content-between'>
                    <label className='form-group-label'>{t("Auto transcribe meetings")}</label>
                    <div className="form-check form-switch" style={{ color: "red" }} id="switchCheckDefault" >
                        <input
                            className="form-check-input pointer"
                            type="checkbox"
                            ref={transcriptionRef}
                            role="switch"
                            onClick={(e) => toggleAutoTranscribe()}
                            defaultChecked={autoTranscribeBool}
                            style={{ backgroundColor: !autoTranscribeBool ? 'white' : '#059628',borderColor:'#059628' }}
                        />
                    </div>
                </div> */}

                <div class="text-center">
                    {saveInProgress
                        ?

                        <div className="spinner-border text-primary" style={{ marginTop: '12px' }} role="status">

                        </div>

                        : <button class="btn theme-btn mt-3" onClick={saveSettings}>{t("Save")}</button>}
                </div>



            </Modal.Body>
        </Modal>
    )
}

export default MeetingtorOptionsModal;