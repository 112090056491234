import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../Auth/AuthContext';
import { set } from 'react-hook-form';
import { t } from '../components/common/translation'

import { showToast } from "../components/common/toast";
import { useModal } from "../components/common/Modal/ModalContext";
function CalendarBody({onCalendarDisconnected, linkedCalendars, credits, isTrial}) {

	const [loadingConnectGoogle, setLoadingConnectGoogle] = useState(false);
	const [loadingConnectMicrosoft, setLoadingConnectMicrosoft] = useState(false);
	const [calendarsLoading, setCalendarsLoading] = useState(true);
	const { currentUser, hashedId, planInfo } = useAuth();
	const { showModal } = useModal();

	useEffect(() => {
		setCalendarsLoading(false);
	}, [linkedCalendars]);


	const truncate = (str, maxLength) => {
		return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
	  };

	const connectCalendar = async (type) => {
		if (credits <= 0 && isTrial) {
			showModal("UPGRADE-CONNECT");
			return;
		}

		if (type === "google") {
			setLoadingConnectGoogle(true);
		} else if (type === "microsoft") {
			setLoadingConnectMicrosoft(true);
		}
			try {
				const response = await fetch('https://zj3s8lf026.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-ConnectCalendar', {
					method: 'POST',
					headers: {
					},
					body: JSON.stringify({ "type": type, "uid": hashedId })
				});
				if (!response.ok) {
					if (response.status === 406) {
					  showModal("UPGRADE-CONNECT");
					} else {
					  const data = await response.json();
					  showToast(data.message, { type: "error" });
					}
				  } else {
					const data = await response.json();
					window.open(data.url, "_self");
				  }
					
	
			} catch (error) {
				console.log(error);
				setLoadingConnectGoogle(false);
			setLoadingConnectMicrosoft(false);
				
			}

	}
	
	const onDisconnectCalendar = async (calendarId) => {
		setCalendarsLoading(true);
		fetch(`https://lhouy22b05.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-DisconnectCalendar?calendarId=${calendarId}`).then(res => res.json()).then((result) => {
			if (onCalendarDisconnected)
				{onCalendarDisconnected();}
		}).catch((error) => {
			console.log(error);
		 });
	  };

	return (
				<div >
					<div class="calendar-border-list border-bottom-0 pb-0">
						<p class="ft-18-title dark-color mb-1">{t('Calendars')}</p>
						<p class="light-color mb-0">{t('Sync your calendar to ensure all your meetings are recorded, so you never miss any important discussions.')}</p>
					</div>

					<div class="calendar-border-list calendar-list-flex">
						<div class="d-flex align-items-center">
							<span class="calendar-icon me-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 48 48" fill="none">
									<path d="M43.607 23.752L36.445 19.58V31.174H44V24.436C44 24.155 43.85 23.894 43.607 23.752Z" fill="#1A237E" />
									<path d="M33.919 8.84H42.965V7.732C42.965 6.775 42.19 6 41.234 6H17.667C16.711 6 15.935 6.775 15.935 7.732V8.84H24.94H33.919Z" fill="#0C4999" />
									<path d="M33.919 33.522H41.233C42.189 33.522 42.965 32.747 42.965 31.79V24.963H33.919V33.522Z" fill="#0F73D9" />
									<path d="M15.936 24.964V31.791C15.936 32.747 16.711 33.523 17.668 33.523H24.941V24.965L15.936 24.964Z" fill="#0F439D" />
									<path d="M33.919 8.84H42.965V16.867H33.919V8.84Z" fill="#2ECDFD" />
									<path d="M15.936 8.84H24.941V16.867H15.936V8.84Z" fill="#1C5FB0" />
									<path d="M24.94 24.964H33.919V33.522H24.94V24.964Z" fill="#1467C7" />
									<path d="M24.94 8.84H33.919V16.867H24.94V8.84Z" fill="#1690D5" />
									<path d="M33.919 16.867H42.965V24.963H33.919V16.867Z" fill="#1BB4FF" />
									<path d="M15.936 16.867H24.941V24.963H15.936V16.867Z" fill="#074DAF" />
									<path d="M24.94 16.867H33.919V24.963H24.94V16.867Z" fill="#2076D4" />
									<path d="M15.441 42C15.904 42 42.311 42 42.311 42C43.244 42 44 41.244 44 40.311V24.438C44 24.438 43.97 25.096 42.249 26.055C40.949 26.779 14.744 41.566 14.744 41.566C14.744 41.566 14.978 42 15.441 42Z" fill="#2ED0FF" />
									<path d="M42.279 41.997C42.118 41.997 15.689 42 15.689 42C14.756 42 14 41.244 14 40.311V25.067L43.363 41.629C43.118 41.825 42.807 41.997 42.279 41.997Z" fill="#139FE2" />
									<path d="M22.319 34H5.681C4.753 34 4 33.247 4 32.319V15.681C4 14.753 4.753 14 5.681 14H22.319C23.247 14 24 14.753 24 15.681V32.319C24 33.247 23.247 34 22.319 34Z" fill="#00488D" />
									<path d="M13.914 18.734C10.783 18.734 8.897 21.126 8.897 24.077C8.897 27.028 10.776 29.419 13.914 29.419C17.053 29.419 18.931 27.027 18.931 24.077C18.931 21.126 17.045 18.734 13.914 18.734ZM13.914 27.616C12.138 27.616 11.076 26.032 11.076 24.077C11.076 22.122 12.143 20.538 13.914 20.538C15.685 20.538 16.753 22.123 16.753 24.077C16.753 26.031 15.689 27.616 13.914 27.616Z" fill="white" />
								</svg>
							</span>
							<div>
								 <p class="ft-16-title dark-color mb-0">Microsoft Outlook</p>
							</div>
						</div>
						{
							loadingConnectMicrosoft ?
								<a href="javascript:void(0);" class="btn theme-btn light-btn loading" id="connect-btn"><span class="spinner"></span></a>
								:
								 <a href="javascript:void(0);" class="btn theme-btn light-btn" id="connect-btn1" onClick={() => connectCalendar('microsoft')}>{t('Connect')}</a>}
					</div>

					<div class="calendar-border-list calendar-list-flex">
						<div class="d-flex align-items-center">
						<span class="calendar-icon me-2">
								<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 48 48" fill="none">
									<path d="M35 13H13V35H35V13Z" fill="white" />
									<path d="M25.68 20.92L26.688 22.36L28.272 21.208V29.56H30V18.616H28.56L25.68 20.92Z" fill="#1E88E5" />
									<path d="M22.943 23.745C23.568 23.171 23.956 22.375 23.956 21.496C23.956 19.749 22.423 18.328 20.539 18.328C18.937 18.328 17.567 19.337 17.209 20.781L18.866 21.202C19.031 20.538 19.734 20.056 20.539 20.056C21.481 20.056 22.248 20.702 22.248 21.496C22.248 22.29 21.481 22.936 20.539 22.936H19.542V24.664H20.539C21.62 24.664 22.532 25.415 22.532 26.304C22.532 27.208 21.666 27.944 20.601 27.944C19.639 27.944 18.817 27.334 18.687 26.526L17 26.802C17.262 28.438 18.81 29.672 20.6 29.672C22.607 29.672 24.24 28.161 24.24 26.304C24.24 25.281 23.736 24.363 22.943 23.745Z" fill="#1E88E5" />
									<path d="M34 42H14L13 38L14 34H34L35 38L34 42Z" fill="#FBC02D" />
									<path d="M38 35L42 34V14L38 13L34 14V34L38 35Z" fill="#4CAF50" />
									<path d="M34 14L35 10L34 6H9C7.343 6 6 7.343 6 9V34L10 35L14 34V14H34Z" fill="#1E88E5" />
									<path d="M34 34V42L42 34H34Z" fill="#E53935" />
									<path d="M39 6H34V14H42V9C42 7.343 40.657 6 39 6Z" fill="#1565C0" />
									<path d="M9 42H14V34H6V39C6 40.657 7.343 42 9 42Z" fill="#1565C0" />
								</svg>
							</span>
							<div>
								 <p class="ft-16-title dark-color mb-0">Google Calendar</p>
							</div>
						</div>
						{
							loadingConnectGoogle ?
								<a href="javascript:void(0);" class="btn theme-btn light-btn loading" id="connect-btn"><span class="spinner"></span></a>
								:
								 <a href="javascript:void(0);" class="btn theme-btn light-btn" id="connect-btn1" onClick={() => connectCalendar('google')}>{t('Connect')}</a>}
					</div>

					{calendarsLoading ? <div className='d-flex justify-content-center text-center'>
														<div class="spinner-border text-primary" role="status">
															<span class="sr-only">Loading...</span>
														</div>
													</div> :
						<ul class="calendar-list" style={{listStyleType:'none',
						 textIndent: '0', paddingLeft: '0'}}> 
							{linkedCalendars?.map((calendar, index) => (
								
									<div class="calendar-border-list calendar-list-flex">
						<div class="d-flex align-items-center">
							<span class="calendar-icon me-2">
								{calendar.type === "google" ?
								<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 48 48" fill="none">
									<path d="M35 13H13V35H35V13Z" fill="white" />
									<path d="M25.68 20.92L26.688 22.36L28.272 21.208V29.56H30V18.616H28.56L25.68 20.92Z" fill="#1E88E5" />
									<path d="M22.943 23.745C23.568 23.171 23.956 22.375 23.956 21.496C23.956 19.749 22.423 18.328 20.539 18.328C18.937 18.328 17.567 19.337 17.209 20.781L18.866 21.202C19.031 20.538 19.734 20.056 20.539 20.056C21.481 20.056 22.248 20.702 22.248 21.496C22.248 22.29 21.481 22.936 20.539 22.936H19.542V24.664H20.539C21.62 24.664 22.532 25.415 22.532 26.304C22.532 27.208 21.666 27.944 20.601 27.944C19.639 27.944 18.817 27.334 18.687 26.526L17 26.802C17.262 28.438 18.81 29.672 20.6 29.672C22.607 29.672 24.24 28.161 24.24 26.304C24.24 25.281 23.736 24.363 22.943 23.745Z" fill="#1E88E5" />
									<path d="M34 42H14L13 38L14 34H34L35 38L34 42Z" fill="#FBC02D" />
									<path d="M38 35L42 34V14L38 13L34 14V34L38 35Z" fill="#4CAF50" />
									<path d="M34 14L35 10L34 6H9C7.343 6 6 7.343 6 9V34L10 35L14 34V14H34Z" fill="#1E88E5" />
									<path d="M34 34V42L42 34H34Z" fill="#E53935" />
									<path d="M39 6H34V14H42V9C42 7.343 40.657 6 39 6Z" fill="#1565C0" />
									<path d="M9 42H14V34H6V39C6 40.657 7.343 42 9 42Z" fill="#1565C0" />
								</svg>
								:
								<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 48 48" fill="none">
									<path d="M43.607 23.752L36.445 19.58V31.174H44V24.436C44 24.155 43.85 23.894 43.607 23.752Z" fill="#1A237E" />
									<path d="M33.919 8.84H42.965V7.732C42.965 6.775 42.19 6 41.234 6H17.667C16.711 6 15.935 6.775 15.935 7.732V8.84H24.94H33.919Z" fill="#0C4999" />
									<path d="M33.919 33.522H41.233C42.189 33.522 42.965 32.747 42.965 31.79V24.963H33.919V33.522Z" fill="#0F73D9" />
									<path d="M15.936 24.964V31.791C15.936 32.747 16.711 33.523 17.668 33.523H24.941V24.965L15.936 24.964Z" fill="#0F439D" />
									<path d="M33.919 8.84H42.965V16.867H33.919V8.84Z" fill="#2ECDFD" />
									<path d="M15.936 8.84H24.941V16.867H15.936V8.84Z" fill="#1C5FB0" />
									<path d="M24.94 24.964H33.919V33.522H24.94V24.964Z" fill="#1467C7" />
									<path d="M24.94 8.84H33.919V16.867H24.94V8.84Z" fill="#1690D5" />
									<path d="M33.919 16.867H42.965V24.963H33.919V16.867Z" fill="#1BB4FF" />
									<path d="M15.936 16.867H24.941V24.963H15.936V16.867Z" fill="#074DAF" />
									<path d="M24.94 16.867H33.919V24.963H24.94V16.867Z" fill="#2076D4" />
									<path d="M15.441 42C15.904 42 42.311 42 42.311 42C43.244 42 44 41.244 44 40.311V24.438C44 24.438 43.97 25.096 42.249 26.055C40.949 26.779 14.744 41.566 14.744 41.566C14.744 41.566 14.978 42 15.441 42Z" fill="#2ED0FF" />
									<path d="M42.279 41.997C42.118 41.997 15.689 42 15.689 42C14.756 42 14 41.244 14 40.311V25.067L43.363 41.629C43.118 41.825 42.807 41.997 42.279 41.997Z" fill="#139FE2" />
									<path d="M22.319 34H5.681C4.753 34 4 33.247 4 32.319V15.681C4 14.753 4.753 14 5.681 14H22.319C23.247 14 24 14.753 24 15.681V32.319C24 33.247 23.247 34 22.319 34Z" fill="#00488D" />
									<path d="M13.914 18.734C10.783 18.734 8.897 21.126 8.897 24.077C8.897 27.028 10.776 29.419 13.914 29.419C17.053 29.419 18.931 27.027 18.931 24.077C18.931 21.126 17.045 18.734 13.914 18.734ZM13.914 27.616C12.138 27.616 11.076 26.032 11.076 24.077C11.076 22.122 12.143 20.538 13.914 20.538C15.685 20.538 16.753 22.123 16.753 24.077C16.753 26.031 15.689 27.616 13.914 27.616Z" fill="white" />
								</svg>}
							</span>
							<div>
								{ calendar.type === "google" ?
									<p class="ft-16-title dark-color mb-0">{ truncate(calendar.current_email, 20)}</p>
								:
									<p class="ft-16-title dark-color mb-0">{ truncate(calendar.current_email, 20)}</p> }	
							</div>
						</div>
									<div class="text-end mt-md-0 mt-2">
										<a href="#" class="btn theme-btn" style={{ marginRight: '10px' }}>
											<span class="me-2">
												<svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
													<path d="M1 6L5 10L13.5 1.5" stroke="#F9FCFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>{t('Connected')}
										</a>
										<a href="#" class="btn theme-btn red-icon-btn" onClick={() => showModal("DISCONNECT-CALENDAR", {calendarId: calendar.calendar_id}, {onDisconnectCalendar})}
										
										>
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
													<g clipPath="url(#clip0_160_1929)">
														<path d="M9.66302 12.8987C9.81193 13.0476 9.81193 13.2891 9.66302 13.438L8.24339 14.8576C6.36016 16.7408 3.29586 16.7409 1.41247 14.8576C-0.470792 12.9743 -0.470792 9.90999 1.41247 8.02673L2.83213 6.60707C2.98104 6.45816 3.22248 6.45816 3.37143 6.60707L4.62975 7.8654C4.77866 8.01431 4.77866 8.25575 4.62975 8.40469L3.21009 9.82432C2.31803 10.7164 2.31803 12.1679 3.21009 13.06C4.10215 13.9521 5.55368 13.9521 6.4458 13.06L7.86543 11.6404C8.01434 11.4915 8.25579 11.4915 8.40473 11.6404L9.66302 12.8987ZM7.86543 4.62968C8.01434 4.77859 8.25579 4.77859 8.40473 4.62968L9.82436 3.21006C10.7164 2.3179 12.1679 2.31797 13.0601 3.21006C13.9521 4.10215 13.9521 5.55365 13.0601 6.44574L11.6404 7.86536C11.4915 8.01427 11.4915 8.25572 11.6404 8.40466L12.8987 9.66299C13.0476 9.8119 13.2891 9.8119 13.438 9.66299L14.8577 8.24333C16.741 6.36003 16.741 3.29573 14.8577 1.41243C12.9744 -0.470828 9.91009 -0.470828 8.02677 1.41243L6.60714 2.83206C6.45823 2.98097 6.45823 3.22242 6.60714 3.37136L7.86543 4.62968ZM15.3277 16.0467L16.0467 15.3277C16.3446 15.0298 16.3446 14.5469 16.0467 14.2491L2.02101 0.223352C1.72316 -0.0744989 1.24027 -0.0744989 0.942449 0.223352L0.223388 0.942413C-0.0744626 1.24026 -0.0744626 1.72315 0.223388 2.02097L14.2491 16.0467C14.547 16.3445 15.0299 16.3445 15.3277 16.0467Z" fill="#F9FCFF" />
													</g>
													<defs>
														<clipPath id="clip0_160_1929">
															<rect width="16.2701" height="16.2701" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</span>
										</a>
									</div>
									
					</div>
								
							))}
						</ul>


					}
					

				</div>
	);
}

export default CalendarBody;
