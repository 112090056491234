import React from "react";
import { t } from "./common/translation";
import FullScreenDialogGoogle from "./FullScreenDialogGoogle";
import FullScreenDialogTeams from "./FullScreenDialogTeams";
import FullScreenDialogZoom from "./FullScreenDialogZoom";

export default function MeetingGuide() {
  return (
    <>
      <div className="card card-padding card-margin">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div
            className="accordion-item"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "0px" }}>
              <h3
                
                style={{ fontSize: "12px" }}
              >
                {t(
                  "Click an icon below for steps to share meeting URL and to add 'Meetingtor' to your meeting."
                )}
              </h3>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FullScreenDialogGoogle />
              <FullScreenDialogTeams />
              <FullScreenDialogZoom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
