import React from 'react';
import logo from '../../assets/img/logo_nobuffer.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useModal } from '../common/Modal/ModalContext';

const LogoDetails = () => {
    const location = useLocation();
    const { showModal } = useModal();
    const navigate = useNavigate();
    const link = "/dashboard"
    const handleClick = () => {
        if (location.pathname.startsWith("/editor")) {
            showModal("CONFIRM-EDITOR", { link }, { onLeaveEditor })
        } else {
            navigate(link)
            console.log("link", link)
        }
    }
    const onLeaveEditor = (link) => {
        navigate(link)
    }
    return (
        <div className="sidebar-header" >

            <a onClick={handleClick}>
                <img
                    className="img-fluid"
                    src={logo}
                    alt="img"
                    width="70%"
                    style={{ margin: "auto", display: "block" }}
                />
            </a>
        </div>
    );
};

export default LogoDetails;
