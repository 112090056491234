import React, { useRef, useState, useEffect, } from 'react';
import RecordRTC from 'recordrtc';
import { useAuth } from '../../Auth/AuthContext';
import LanguageDropdown from '../../components/common/transcribeLanguageDropdown';
import ServiceDropdown from '../../components/common/transcribeServiceDropdown';
import { uploadToS3 } from '../Uploader';
import { useNavigate } from 'react-router-dom';
import recordSvg from '../../assets/img/icon/record.svg';
import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import recordPng2 from '../../assets/img/record/record-img2.png';
import recordPng3 from '../../assets/img/record/record-img3.png';
import recordMac from "../../assets/img/record/allsteps-mac.png"
import recordMac2 from "../../assets/img/record/secondstep-mac.png"
import playstorePng from '../../assets/img/icon/playstore.png';
import appstorePng from '../../assets/img/icon/appstore.png';
import { t } from '../../components/common/translation'
import { showToast } from '../../components/common/toast';

const Recorder = ({ setIsBlocked }) => {
  const { hashedId } = useAuth();
  const isChrome = !!window.navigator.userAgent.match(/Chrome/i) && !window.navigator.userAgent.match(/Edge/i);
  const [recording, setRecording] = useState(false);
  const [readyToRecord, setReadyToRecord] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [paused, setPaused] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0); // in seconds
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("transcript-language"));
  const [selectedService, setSelectedService] = useState(localStorage.getItem("transcript-service"));
  const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
  const [recordedAudioBlob, setRecordedAudioBlob] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isOnlyMic, setIsOnlyMic] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null)
  const navigate = useNavigate();
  const videoRecorder = useRef(null);
  const screenStreamRef = useRef(null);
  const micStreamRef = useRef(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioDestination, setAudioDestination] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  let temp_platform = navigator.platform.toLowerCase();
  const [platform, setPlatform] = useState(null);
  const blobs = useRef(null);
  const blob = useRef(null);
  const audioblobs = useRef(null)
  const audioblob = useRef(null)
  const rec = useRef(null);
  var audiorec = useRef(null)
  const stream = useRef(null);
  const voiceStream = useRef(null)
  const desktopStream = useRef(null);
  const finalblob = useRef(null)
  const videoDownloadRef = useRef(null)

  useEffect(() => {
    if (temp_platform.indexOf('win') !== -1) {
      setPlatform("win")
    } else if (temp_platform.indexOf('mac') !== -1) {
      console.log('User is using Mac');
      setPlatform("mac")

    } else {
      console.log('User is using another operating system');
      setPlatform("other")
    }
  }, []);



  useEffect(() => {
    if (uploading || recording || stopped) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
  }, [uploading, recording, stopped])
  useEffect(() => {
    let timer;
    if (recording && !paused) {
      timer = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [recording, paused]);
  const togglePauseRecording = () => {
    if (paused) {
      audiorec.current.resume();
      rec.current.resume();
      setPaused(false);
    } else {
      audiorec.current.pause();
      rec.current.pause();
      setPaused(true);
    }
  };
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    const sec = seconds - (hours * 3600) - (minutes * 60);

    // If it has hours
    if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
    }
    // If it's only minutes and seconds (but not just seconds)
    else if (minutes > 0) {
      return `${minutes.toString().padStart(2, '0')}.${sec.toString().padStart(2, '0')}`;
    }
    // If it's only seconds
    else {
      return `00.${sec.toString().padStart(2, '0')}`;
    }
  };

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };


  const prepareRecording = async () => {
    desktopStream.current = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    });

    voiceStream.current = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    desktopStream.current.getVideoTracks()[0].onended = function () {
      stopRecording();
    };

    const tracks = [
      ...desktopStream.current.getVideoTracks(),
      ...mergeAudioStreams(desktopStream.current, voiceStream.current),
    ];

    stream.current = new MediaStream(tracks);

    const audiotracks2 = [...mergeAudioStreams(desktopStream.current, voiceStream.current)];

    let audiostream2 = new MediaStream(audiotracks2);

    blobs.current = [];
    audioblobs.current = [];

    rec.current = new MediaRecorder(stream.current, { mimeType: "video/webm; codecs=vp9" });
    rec.current.ondataavailable = (e) => blobs.current.push(e.data);
    audiorec.current = new MediaRecorder(audiostream2, { mimeType: "audio/webm" });
    audiorec.current.ondataavailable = (e) => audioblobs.current.push(e.data);

    rec.current.onstop = async () => {
      //blobs.push(MediaRecorder.requestData());
      blob.current = new Blob(blobs.current, { type: "video/webm" });

      // Convert Blob to File
      const file = new File([blob.current], "filename.webm", { type: "video/webm" });

      // Use the file object as needed
      const newObjectUrl = URL.createObjectURL(file);
      setVideoSrc(newObjectUrl);
      console.log("kekek")
      audioblob.current = new Blob(audioblobs.current, { type: "audio/webm" });
      desktopStream.current.getTracks().forEach((track) => track.stop());
      voiceStream.current.getTracks().forEach((track) => track.stop());
    };

    audiorec.current.onstop = async () => {
      audioblob.current = new Blob(audioblobs.current, { type: "audio/webm" });
    };




    if (desktopStream.current.getAudioTracks().length > 0) {

      setIsOnlyMic(false);
    } else {
      setIsOnlyMic(true);
    }

    setReadyToRecord(true);


  };



  const startRecording = () => {
    rec.current.start();

    audiorec.current.start();
    setRecording(true);

  };


  const stopRecording = () => {
    audiorec.current.stop();
    rec.current.stop();

    stream.current.getTracks().forEach((s) => s.stop());
    stream.current = null;
    setRecording(false);
    setReadyToRecord(false);
    setStopped(true);
    //downloadBlob(finalblob.current, "Transkriptor.webm")
  };


  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  const handleServiceChange = (service) => {
    setSelectedService(service);
  }

  const handleUpload = async () => {
    setUploading(true);
    var d = new Date(),
      dformat =
        [
          (d.getMonth() + 1).toString().padStart(2, '0'),
          d.getDate().toString().padStart(2, '0'),
          d.getFullYear(),
        ].join("-") +
        " " +
        [
          d.getHours().toString().padStart(2, '0'),
          d.getMinutes().toString().padStart(2, '0'),
          d.getSeconds().toString().padStart(2, '0'),
        ].join(":");

    console.log(dformat);
    var filename = dformat + ".webm";
    // flow for files smaller than 5 mb
    const generalUploadUrl = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&filename=${filename}`;

    try {
      // Step 1: Get the pre-signed URL from your server
      const response = await fetch(generalUploadUrl);
      const data = await response.json();
      const presignedUrl = data['the_url'];
      console.log('Received a presigned URL:', presignedUrl);

      // Step 2: Upload the file to S3
      const uploadResult = await uploadToS3(presignedUrl, audioblob.current, (progress) => {
        setUploadProgress(progress);
        console.log(`Upload progress: ${progress}%`);
      });
      console.log('File uploaded successfully:', uploadResult);

      // Step 3: Notify your server about the successful upload
      const endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${hashedId}&filename=${filename}&language=${selectedLanguage}&service=${selectedService}`;
      const endpointResponse = await fetch(endpointUrl);
      const endpointData = await endpointResponse.json();
      console.log('Endpoint data:', endpointData);

      // Navigate to the files route
      navigate('/files');

    } catch (err) {
      console.error('Error uploading file:', err);
    }

    setUploading(false);

  };

  const downloadVideoSrc = (videoSrc, filename) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = videoSrc; // Use videoSrc directly
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    // Do setup work here…

    return () => {
      // Clean up resources here…
      if (desktopStream.current) desktopStream.current.getTracks().forEach(track => track.stop());
      if (voiceStream.current) voiceStream.current.getTracks().forEach(track => track.stop());
      // …other cleanup…
    };
  }, []); // Dependency Array
  useEffect(() => {
    if (videoSrc) {
      downloadVideoSrc(videoSrc, 'Transkriptor.webm');
    }
  }, [videoSrc]);


  return (
    <>

      {isChrome ? (
        <>
          <div className='row justify-content-center d-lg-flex '>
            {isMobile && <>
              <div className='col-lg-6 card-margin'>
                <div className='card card-padding flex-center card-100 text-center'>
                  <div>
                    <span class="jsontranslate">{t("Download our mobile application to record with your mobile device or use Google Chrome Desktop on your computer to record your meetings.")}</span>
                    <br></br><br></br>
                    <a href="https://play.google.com/store/apps/details?id=com.transkriptor.app">
                      <img width="90%" src={playstorePng} class="attachment-full size-full android-content" alt="" />
                    </a>
                    <a href="https://apps.apple.com/us/app/transcribe-speech-to-text/id1610386353" >
                      <img width="90%" src={appstorePng} class="attachment-large size-large ios-content" alt="" loading="lazy" />								</a>


                  </div>

                </div>
              </div>
            </>}
          </div>

          <div class="row d-lg-flex  justify-content-center">
            {!isMobile && !readyToRecord && !recording && !stopped && !paused && (
              <div class="col-lg-6 card-margin">
                <div class="card card-padding flex-center card-100 text-center">
                  <div class="img-icon-list">
                    <span className='pointer round-color-icon red-bg recorder-span'><img src={recordPng} class="img-fluid recorder-img" alt="img" /></span>
                  </div>
                  <p class="ft-18-title mb-3">{t("Record Audio")}</p>
                  <p>{t("Tip: Share system audio for remote meetings to record speaker in addition to microphone")}</p>
                  <button class="btn theme-btn" onClick={prepareRecording}>
                    <span class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-broadcast" viewBox="0 0 16 16">
                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                      </svg>
                    </span>{t("Share Audio")}
                  </button>
                </div>
              </div>
            )}
            {readyToRecord && !recording && !stopped && !paused && !isOnlyMic && (
              <div class="col-lg-6 card-margin">
                <div class="card card-padding flex-center card-100 text-center">
                  <button class="btn theme-btn mb-4" onClick={startRecording}>
                    <span class="btn-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16">
                        <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      </svg>
                    </span>{t("Start Recording")}
                  </button>
                  <p>{t("Microphone + Speaker recording is enabled. Use headphones for the best recording quality.")}</p>
                </div>
              </div>
            )}
            {readyToRecord && !recording && !stopped && !paused && isOnlyMic && (
              <div class="col-lg-6 card-margin">
                <div class="card card-padding card-100 text-center">
                  <div class="img-icon-list">
                    <span><img src={recordSvg} class="img-fluid" alt="img" /></span>
                  </div>
                  <p class="ft-18-title mb-3">{t("Record Audio")}</p>
                  <p>{t("Only Microphone recording is enabled. if you want to record remote meeting we recommend to refresh this page and share system or tab audio as well")}</p>
                  <div>
                    <button class="btn theme-btn me-1 mb-2" onClick={() => window.location.reload()}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                      </span>{t("Refresh Page")}
                    </button>
                    <button class="btn theme-btn me-1 mb-2" onClick={startRecording}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16">
                          <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </span>{t("Start Recording")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {recording && !stopped && !paused && (
              <div class="col-lg-6 card-margin">
                <div class="card card-padding card-100 text-center theme-bg white-color">
                  <div class="record-time-div">{formatTime(recordingTime)}</div>
                  <div class="text-center mb-2 mt-3">
                    <button class="btn theme-btn transparent-white-btn mb-2 me-1" onClick={togglePauseRecording}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                          <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                        </svg>
                      </span>{t("Pause Recording")}
                    </button>
                    <button class="btn theme-btn transparent-white-btn mb-2 me-1" onClick={stopRecording}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16">
                          <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                        </svg>
                      </span>{t("Stop Recording")}
                    </button>
                  </div>
                  <p>{t("Microphone + Speaker recording is enabled. Use headphones for the best recording quality.")}</p>
                </div>
              </div>
            )}
            {recording && paused && !stopped && (
              <div class="col-lg-6 card-margin">
                <div class="card card-padding card-100 text-center theme-bg white-color">
                  <div class="record-time-div">{formatTime(recordingTime)}</div>
                  <div class="text-center mb-2 mt-3">
                    <button class="btn theme-btn transparent-white-btn mb-2 me-1" onClick={togglePauseRecording}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                          <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                        </svg>
                      </span>{t("Resume Recording")}
                    </button>
                    <button class="btn theme-btn transparent-white-btn mb-2 me-1" onClick={stopRecording}>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16">
                          <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                        </svg>
                      </span>{t("Stop Recording")}
                    </button>
                  </div>
                  <p>{t("Microphone + Speaker recording is enabled. Use headphones for the best recording quality.")}</p>
                </div>
              </div>
            )}
            {stopped && !recording && !paused && !uploading && (
              <div class="col-12 card-margin">
                <div class="card card-padding overflow-visible card-100">
                  <div>
                    <p class="dark-color regular d-inline-block me-3">
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                          <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
                        </svg>
                      </span>{ }
                    </p>
                    <p class="dark-color regular d-inline-block">
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                      </span>{formatTime(recordingTime)}
                    </p>
                  </div>

                  <div class="row">
                    <div class="col-lg-5 col-md-7">
                      <LanguageDropdown onLanguageChange={handleLanguageChange} />

                    </div>
                    <div class="col-lg-4 col-md-5">
                      <ServiceDropdown onServiceChange={handleServiceChange} />
                    </div>
                    <div class="col-lg-3 text-lg-start text-center">
                      <button class="btn theme-btn mb-4" onClick={() => handleUpload()}>{t("Transcribe")}</button>
                    </div>
                  </div>
                  <video src={videoSrc} ref={videoDownloadRef} controls autoPlay className='mb-3 w-100' />

                </div>
              </div>
            )}
            {stopped && !recording && !paused && uploading && (
              <div class="col-12 card-margin">
                <div class="card card-padding card-100 text-center">
                  <div className="progress mb-4">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                      style={{ width: `${uploadProgress}%` }}
                      aria-valuenow={uploadProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>


                  </div>
                  <strong>{uploadProgress.toPrecision(3)}%</strong>

                  <p class="ft-18-title mb-1">{t("Please Wait")}</p>
                  <p>{t("Please wait until your recording is updated to cloud. your recording will be transcribed.")}</p>
                </div>
              </div>)}

          </div>
          <div class="row justify-content-center d-lg-flex ">
            <div class="col-12 sec-pad-top">
              <h3 class="medium-title text-center mb-lg-5 mb-4">{t("How to Start Recording Your Audio")}</h3>
              {platform === "win" && (
                <div class="card card-padding card-margin">
                  <div class="row justify-content-center">
                    <div class="col-lg-6">
                      <img src={recordPng2} class="img-fluid mb-4" alt="img" />
                    </div>
                    <div class="col-lg-6">
                      <img src={recordPng3} class="img-fluid mb-4" alt="img" />
                    </div>
                    <div class="col-xxl-12">
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("1- Select the 'Entire Screen' tab")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("2- Click on the screen image")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("3- If you are conducting a remote meeting please makes sure that 'Share system audio' is enabled to record other attendees.")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("4- Click on 'Share' button")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("5- Click on 'Allow' button to record from your microphone")}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                  </div>
                </div>
              )}
              {platform === "mac" && (
                <div class="card card-padding card-margin">
                  <div class="row justify-content-center">
                    <div class="col-lg-6">
                      <img src={recordMac} class="img-fluid mb-4" alt="img" />
                    </div>
                    <div class="col-lg-6">
                      <img src={recordMac2} class="img-fluid mb-4" alt="img" />
                    </div>
                    <div class="col-xxl-12">
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("1- Select the 'Chrome tab'.")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("2- Select the Chrome tab that you are conducting the meeting.")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("3- Please makes sure that 'Share tab audio' is enabled to record other attendees.")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("4- Click on 'Share' button")}
                      </p>
                      <p class="dark-color regular d-flex align-items-start mb-2">
                        <span class="fa fa-angle-double-right theme-color mt-1 me-2"></span>
                        {t("5- Click on 'Allow' button to record from your microphone")}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row justify-content-center d-lg-flex align-items-center text-center"
            style={{ height: '200px', display: 'flex', alignItems: 'center' }}>
            <div className="card card-padding card-100 flex-center card-hover text-center"
              style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="w-100 d-sm-flex d-block align-items-center justify-content-center">
                <h4>{t("Please use Google Chrome to record audio/video!")}</h4>

              </div>
              <img src='https://www.google.com/chrome/static/images/chrome-logo-m100.svg' className='img-fluid m-2'></img>
              <button class="btn theme-btn mb-1" onClick={() => window.open('https://www.google.com/chrome/', '_blank')}>
                {t("Get Google Chrome")}
              </button>
            </div>
          </div>

        </>
      )}
    </>
  );
};

export default Recorder;

