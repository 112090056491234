import React, { useState, useRef } from 'react';
import { showToast } from "./common/toast";
import { useModal } from './common/Modal/ModalContext';
import { t } from '../components/common/translation'
import { useAuth } from '../Auth/AuthContext';

import googleMeet from '../assets/img/icon/google-meet.svg';
import zoom from '../assets/img/icon/zoom.svg';
import microsoftTeams from '../assets/img/icon/microsoft-teams.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
function MeetingBox({ index, meeting, credits, isTrial }) {
  const [recordingEnabled, setRecordingEnabled] = useState(!meeting.disabled);
  const [transcriptionEnabled, setTranscriptionEnabled] = useState(meeting.auto_transcribe ?? false);

  const { showModal } = useModal();

  const recordingRef = useRef(null);
  const transcriptionRef = useRef(null);

  const truncate = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };

  const toggleRecording = async (val, eventId) => {
    if (credits <= 0 && isTrial) {
      recordingRef.current.click();
      showModal("UPGRADE-RECORD");
      return;
    }
    if (val) {
      if (transcriptionRef.current && transcriptionEnabled) transcriptionRef.current.click();
    }
    setRecordingEnabled(!val);
    try {
      const response = await fetch('https://457kh1l7qa.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-ToggleMeetings', {
        method: 'POST',
        body: JSON.stringify({ "status": val ? 'off' : "on", "eventId": eventId })
      })
      if (!response.ok) {
        if (response.status === 406) {
          showModal("UPGRADE-RECORD");
        } else {
          const data = await response.json();
          showToast(data.message, { type: "error" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleTranscription = async (val, eventId) => {
    if (isTrial) {
      transcriptionRef.current.click();
      showModal("UPGRADE-TRANSCRIBE");
      return;
    }
    if (!val) {
      if (recordingRef.current && !recordingEnabled) recordingRef.current.click();
    }
    setTranscriptionEnabled(!val);
    try {
      const response = await fetch(`https://1hvkjzs6uh.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-SetAutoTranscribeForMeeting?auto_transcribe=${val ? 0 : 1}&event_id=${eventId}`)
      if (!response.ok) {
        if (response.status === 406) {
          showModal("UPGRADE-RECORD");
        } else {
          const data = await response.json();
          showToast(data.message, { type: "error" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };



  function meetingHasExpired(inputDate) {
    return new Date(inputDate) < new Date();
  }

  return (
    <div key={index} className='calendar-meeting-box '>
      <div class="d-flex justify-content-between">
        <div>
          <p class="d-flex align-items-center mb-1">
            <span class="me-1">
              <img src={
                meeting.type == 'google_meet' ?
                  googleMeet
                  :
                  meeting.type == 'zoom' ?
                    zoom
                    :
                    meeting.type == 'microsoft_teams' ?
                      microsoftTeams
                      :
                      googleMeet
              } width="30" height="30" viewBox="0 0 30 30" fill="none" class="img-fluid" alt="img" />
            </span> {new Date(meeting.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(meeting.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </p><p class="ft-18-title light-color mb-0" onClick={() => window.open(meeting.meetingLink, "_blank")
          }
            style={{ cursor: 'pointer' }}>{truncate(meeting.meetingName, 20)}</p>

          <p class="mb-0">{t("Organizer")} : {truncate(meeting.host, 20)}  </p>
        </div>


        {meetingHasExpired(meeting.startDate) ?
          <div class="d-flex flex-column justify-content-end">
            <p class="text-danger mb-0">{t("This meeting has expired")}</p>
          </div>
          :
          <div class="d-flex flex-column justify-content-end" >

            <div class="d-flex justify-content-end text-end">
              <p class="mb-0">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}

                  overlay={(props) => (
                    <Tooltip {...props}>
                      {t('Do you want Meetingtor to attend your meeting?')}
                    </Tooltip>
                  )}
                  placement="bottom"
                ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ cursor: 'pointer', paddingRight: "2" }}>
                    <g clipPath="url(#clip0_153_1651)">
                      <path d="M8.5 0.265625C3.9526 0.265625 0.265625 3.95393 0.265625 8.5C0.265625 13.0487 3.9526 16.7344 8.5 16.7344C13.0474 16.7344 16.7344 13.0487 16.7344 8.5C16.7344 3.95393 13.0474 0.265625 8.5 0.265625ZM8.5 3.91797C9.27018 3.91797 9.89453 4.54232 9.89453 5.3125C9.89453 6.08268 9.27018 6.70703 8.5 6.70703C7.72982 6.70703 7.10547 6.08268 7.10547 5.3125C7.10547 4.54232 7.72982 3.91797 8.5 3.91797ZM10.3594 12.3516C10.3594 12.5716 10.181 12.75 9.96094 12.75H7.03906C6.81903 12.75 6.64062 12.5716 6.64062 12.3516V11.5547C6.64062 11.3347 6.81903 11.1562 7.03906 11.1562H7.4375V9.03125H7.03906C6.81903 9.03125 6.64062 8.85285 6.64062 8.63281V7.83594C6.64062 7.6159 6.81903 7.4375 7.03906 7.4375H9.16406C9.3841 7.4375 9.5625 7.6159 9.5625 7.83594V11.1562H9.96094C10.181 11.1562 10.3594 11.3347 10.3594 11.5547V12.3516Z" fill="#A8ABAE" />
                    </g>
                    <defs>
                      <clipPath id="clip0_153_1651">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </OverlayTrigger>

                {t('Recording')}
              </p>
              <div className="form-check form-switch" style={{ paddingLeft: "3rem", color: "red" }} id="switchCheckDefault" >
                <input
                  ref={recordingRef}
                  className="form-check-input pointer"
                  type="checkbox"
                  role="switch"
                  onClick={(e) => toggleRecording(recordingEnabled, meeting.event_id)}
                  defaultChecked={recordingEnabled ? true : false}
                  style={{ backgroundColor: !recordingEnabled ? 'white' : '#059628', borderColor: '#059628' }}
                />
              </div>
            </div>
            <div class="d-flex justify-content-end text-end">
              <p class="mb-0">
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}

                  overlay={(props) => (
                    <Tooltip {...props}>
                      {t('Do you want Meetingtor to transcribe your meeting?')}
                    </Tooltip>
                  )}
                  placement="bottom"
                ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{ cursor: 'pointer', paddingRight: "2" }}>
                    <g clipPath="url(#clip0_153_1651)">
                      <path d="M8.5 0.265625C3.9526 0.265625 0.265625 3.95393 0.265625 8.5C0.265625 13.0487 3.9526 16.7344 8.5 16.7344C13.0474 16.7344 16.7344 13.0487 16.7344 8.5C16.7344 3.95393 13.0474 0.265625 8.5 0.265625ZM8.5 3.91797C9.27018 3.91797 9.89453 4.54232 9.89453 5.3125C9.89453 6.08268 9.27018 6.70703 8.5 6.70703C7.72982 6.70703 7.10547 6.08268 7.10547 5.3125C7.10547 4.54232 7.72982 3.91797 8.5 3.91797ZM10.3594 12.3516C10.3594 12.5716 10.181 12.75 9.96094 12.75H7.03906C6.81903 12.75 6.64062 12.5716 6.64062 12.3516V11.5547C6.64062 11.3347 6.81903 11.1562 7.03906 11.1562H7.4375V9.03125H7.03906C6.81903 9.03125 6.64062 8.85285 6.64062 8.63281V7.83594C6.64062 7.6159 6.81903 7.4375 7.03906 7.4375H9.16406C9.3841 7.4375 9.5625 7.6159 9.5625 7.83594V11.1562H9.96094C10.181 11.1562 10.3594 11.3347 10.3594 11.5547V12.3516Z" fill="#A8ABAE" />
                    </g>
                    <defs>
                      <clipPath id="clip0_153_1651">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </OverlayTrigger>

                {t('Transcription')}
              </p>
              <div className="form-check form-switch" style={{ paddingLeft: "3rem", color: "red" }} id="switchCheckDefault" >
                <input
                  ref={transcriptionRef}
                  className="form-check-input pointer"
                  type="checkbox"
                  role="switch"
                  onClick={(e) => toggleTranscription(transcriptionEnabled, meeting.event_id)}
                  defaultChecked={transcriptionEnabled}
                  style={{ backgroundColor: !transcriptionEnabled ? 'white' : '#059628', borderColor: '#059628' }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default MeetingBox;
