import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import minutesPng from "../../assets/img/icon/minute-btn-icon.svg";
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg";

import recordPng from "../../assets/img/icon/record-btn-icon.svg";
import botPng from "../../assets/img/icon/bot-btn-icon.svg";
import brandsPng from "../../assets/img/services.png";
import languages from "../../assets/img/icon/language.png";
import logoMeetingtor from "../../assets/img/logo_nobuffer.png";
import logoTranskriptor from "../../assets/img/logo-transkriptor-nobuffer.png";

import logoSpeaktor from "../../assets/img/logo-speaktor-nobuffer.png";
import logoEskritor from "../../assets/img/logo-eskritor-nobuffer.png";
import logoAdmin from "../../assets/img/fulllogo_transparent.png";
import { useApiData } from "../../hooks/useApiData";
import { useModal } from "../common/Modal/ModalContext";
import { loadLanguageTranslations } from "../common/translation";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { t } from "../common/translation";
function Header({ handleOpenSidebar }) {
  const location = useLocation();
  const { hashedId, userData, planInfo } = useAuth();
  const [minutes, setMinutes] = useState(null);
  const [credits, setCredits] = useState(null);
  const { showModal } = useModal();
  const [isTranskriptorRedirect, setIsTranskriptorRedirect] = useState(false);
  const isChrome = window.navigator.userAgent.includes("Chrome");

  useEffect(() => {
    if (userData) {
      setMinutes(userData.Minutes);
      setIsTranskriptorRedirect(sessionStorage.getItem("transkriptor-redirect"));
    }
  }, [userData]);

  useEffect(() => {
    if (planInfo) {
      setCredits(
        planInfo.allowed - planInfo.total_orders + "/" + planInfo.allowed
      );
    }
  }, [planInfo]);

  let text;
  switch (location.pathname) {
    case "/uploader":
      text = "Upload File";
      break;
    case "/recorder":
      text = "Record Audio";
      break;
    case "/files":
      text = "Files";
      break;
    case "/folders":
      text = "Folders";
      break;
    // Add more cases as needed
    default:
      text = "Home"; // default text if no path matches
      break;
  }
  const openLangDialog = (event) => {
    if (event) event.preventDefault();

    showModal("LANGUAGE-MODAL");

    const modalContent = document.body.querySelector(".modal-content");
    if (modalContent) {
      modalContent.classList.add("overflow-visible");
    } else {
      console.error("No element with class .modal-content found!");
    }
  };

  const handleNavigate = (brand) => {

    // Get the current path
    var currentPath = location.pathname;

    if (brand === "transkriptor-redirect") {
      window.location.href = `https://app.transkriptor.com/${currentPath}`;
    } else {
      // Construct new URL
      const newURL = `https://app.${brand}.com/signIn?uid=${hashedId}`;

      // Redirect to the new URL
      if (isChrome) {
        window.open(newURL, "_blank");
      } else {
        window.location.href = newURL;
      }
    }


  };

  useEffect(() => {
    const languageCode = localStorage.getItem("dashboard-language") || "en";
    loadLanguageTranslations(languageCode); // Load translations once when the app starts
  }, []);

  return (
    <>
      <div className="header-div notranslate">
        <div className="header-div-flex" >
          <div className="d-flex align-items-center">
            <div
              className="d-xl-none d-flex align-items-center me-3 pointer"
              id="sidebar-menu-open"
              onClick={handleOpenSidebar}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="text-black "
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 12H20.25"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.75 6H20.25"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.75 18H20.25"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <Link>
                <img src={logoAdmin} class="img-fluid header-logo" alt="img" />
              </Link>
            </div>

            <div className="d-xl-flex d-none">
              {userData?.SStatus == "Trial" && (
                <Link to="payment" className="header-minute-text semibold">
                  <span className="round-color-icon theme-bg">
                    <img src={minutesPng} className="img-fluid" alt="img"></img>
                  </span>{" "}
                  {credits} {t("File Limit")}
                </Link>
              )}

              {isTranskriptorRedirect ? (
                <button className="btn header-btn rounded-btn" onClick={() => handleNavigate("transkriptor-redirect")}>

                  <span className="round-color-icon "
                    style={{ backgroundColor: "#007bff" }}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                    </svg>

                  </span>

                  {t("Go back to Transkriptor")}
                </button>
              ) : (
                <Link to="payment" className="header-minute-text semibold">
                  <span
                    className="round-color-icon"
                    style={{ backgroundColor: "#007bff" }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      className="command-svg"
                      width="25px"
                      height="25px"
                    >
                      <g>
                        <rect fill="none" height="20" width="20" x="0"></rect>
                      </g>
                      <g>
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"
                          ></path>
                          <path
                            fill="#FFFFFF"
                            d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"
                          ></path>
                          <path
                            fill="#FFFFFF"
                            d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"
                          ></path>
                          <path
                            fill="#FFFFFF"
                            d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>{" "}
                  {minutes} {t("AI Credits")}
                </Link>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="pointer" onClick={() => openLangDialog()}>
              <img
                src={languages}
                width="45px"
                class="img-fluid res-header-side-icon me-3"
                alt="img"
              ></img>
            </div>
            <div className="dropdown">
              <Link
                className="btn dropdown-toggle no-toggle-icon"
                role="button"
              >
                <img
                  src={brandsPng}
                  width="45px"
                  class="img-fluid res-header-side-icon"
                  alt="img"
                />
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item active" to="transkriptor">
                    <img
                      src={logoTranskriptor}
                      onClick={() => handleNavigate("transkriptor")}
                      class="img-fluid brand-drop-logo"
                      alt="img"
                    ></img>
                  </Link>
                </li>
                <li>
                  <Link
                    class="dropdown-item"
                    onClick={() => handleNavigate("speaktor")}
                  >
                    <img
                      src={logoSpeaktor}
                      class="img-fluid brand-drop-logo"
                      alt="img"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    class="dropdown-item"
                    onClick={() => handleNavigate("eskritor")}
                  >
                    <img
                      src={logoEskritor}
                      class="img-fluid brand-drop-logo"
                      alt="img"
                    />
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item active" to="transkriptor">
                    <img
                      src={logoMeetingtor}
                      class="img-fluid brand-drop-logo"
                      alt="img"
                    ></img>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="res-header-dash-sticky d-xl-none d-flex">
        {userData?.SStatus == "Trial" && (
          <Link to="payment" className="header-minute-text semibold">
            <span className="round-color-icon theme-bg">
              <img src={minutesPng} className="img-fluid" alt="img"></img>
            </span>{" "}
            {credits} {t("File Limit")}
          </Link>
        )}
        {isTranskriptorRedirect ? (
          <button className="btn header-btn rounded-btn" onClick={() => handleNavigate("transkriptor-redirect")}>

            <span className="round-color-icon m-1"
              style={{ backgroundColor: "#007bff" }}
            >

              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="white" class="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
              </svg>

            </span>

            {t("Go back to Transkriptor")}
          </button>
        ) : (
          <Link to="payment" className="header-minute-text semibold">
            <span
              className="round-color-icon"
              style={{ backgroundColor: "#007bff" }}
            >
              <svg
                viewBox="0 0 24 24"
                className="command-svg"
                width="25px"
                height="25px"
              >
                <g>
                  <rect fill="none" height="20" width="20" x="0"></rect>
                </g>
                <g>
                  <g>
                    <path
                      fill="#FFFFFF"
                      d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"
                    ></path>
                    <path
                      fill="#FFFFFF"
                      d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"
                    ></path>
                    <path
                      fill="#FFFFFF"
                      d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"
                    ></path>
                    <path
                      fill="#FFFFFF"
                      d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>{" "}
            {minutes} {t("AI Credits")}
          </Link>
        )}
      </div>
    </>
  );
}

export default Header;
