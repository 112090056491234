import React from "react";
import { useState, useEffect } from "react";

import Google_Meet from "../assets/img/meeting-bot/Google_Meet.png";
import {t} from "./common/translation";

import VerticalLinearStepperGoogle from "./VerticalLinearStepperGoogle";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog() {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} style={{border: 'none' }}>
        <img src={Google_Meet} alt="Google Meet" style={{ width: '60px', height: '60px', marginRight: '10px'}}/>
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">
          {t("How to add Meetingtor to your Google Meet meeting?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <VerticalLinearStepperGoogle />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t("Okay")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}