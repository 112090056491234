import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {t} from "./common/translation";

import meetingGuideG1 from "../assets/img/meeting-bot/G.1.1.png";
import meetingGuideG2 from "../assets/img/meeting-bot/G.1.2.png";
import meetingGuideG3 from "../assets/img/meeting-bot/G.1.3.png";

const steps = [
  {
    label: t("STEP 1"),
    description: t("Click the 'copy joining info'"),
    image: meetingGuideG1,
  },
  {
    label: t("STEP 2"),
    description: t("Enter the URL and click the 'Recording' button. If you want to transcribe your meeting please click the transcribe the meeting tick box and select the meeting language.'"),
    image: meetingGuideG2,
  },
  {
    label: t("STEP 3"),
    description: t("Admit Meetingtor to your meeting."),
    image: meetingGuideG3,
  },
];

export default function VerticalLinearStepperTeams() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label || `step-${index}`}>
            {step.label && (
              <StepLabel
                optional={index === steps.length - 1 ? <Typography variant="caption">{t("Last step")}</Typography> : null}
              >
                {t(step.label)}
              </StepLabel>
            )}
            <StepContent>
              {step.description && <Typography style={{marginTop: '0', marginBottom: '0', paddingBottom: '0'}}>{t(step.description)}</Typography>} 
              {step.image && ( 
                <Box
                className='meeting-guide-image'
                component="img"
                sx={{ mt: 2, objectFit: 'contain', marginTop: '0', paddingTop: '0' }}
                src={step.image}
                alt={step.label || `Step ${index}`}
              />     
              )}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {index === steps.length - 1 ? t('Finish') : t('Continue')}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    {t("Back")}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>{t("All steps completed - You're ready to use Meetingtor!")}</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            {t("Restart")}
          </Button>
        </Paper>
      )}
    </Box>
  );
}
