import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";

import { useAuth } from '../../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

import { ReactDOM } from 'react';

import rename from "../../assets/img/icon/rename.svg";
import fileMark from '../../assets/img/files/file-mark.svg';
import undo from '../../assets/img/files/undo.svg';
import redo from '../../assets/img/files/redo.svg';
import share from '../../assets/img/files/share.svg';
import tabTranskriptorBlue from '../../assets/img/files/tab-transkriptor-blue.png';
import tabTranskriptorWhite from '../../assets/img/files/tab-transkriptor-white.png';
import tabMeetingtorBlue from '../../assets/img/files/tab-meetingtor-blue.png';
import tabMeetingtorWhite from '../../assets/img/files/tab-meetingtor-white.png';
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";
import { showToast } from "../../components/common/toast";
import { useModal } from "../../components/common/Modal/ModalContext";
import { t } from "../../components/common/translation";

import logoTranskriptor from '../../assets/img/logo-transkriptor-nobuffer.png';
import logoMeetingtor from '../../assets/img/logo_nobuffer_white.png';
import CircularProgress from '@mui/material/CircularProgress';

import ReactPlayer from 'react-player';
import { Modal } from 'react-bootstrap';
import VideoControls from './VideoControls';
import './MeetingView.css';
import { Container } from "@mui/material";

import screenfull from 'screenfull';
import { set } from 'react-hook-form';

function MeetingView({ handleOpenSidebar }) {
	const [playing, setPlaying] = useState(false);
	const [volume, setVolume] = useState(0.5);
	const [muted, setMuted] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [played, setPlayed] = useState(0);
	const [loaded, setLoaded] = useState(0);
	const [seeking, setSeeking] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const videoContainerRef = useRef(null);
	const playerRef = useRef(null);
	const controlRef = useRef(null);
	const containerClass = isFullscreen ? 'fullscreen-mode' : '';

	const handleMute = () => {
		if (!muted) {
			setPrevVolume(volume);
			setVolume(0);
		} else {
			setVolume(prevVolume);
		}
		setMuted(!muted);
	};

	const { tid, uid, oid } = useParams();
	const { userData, hashedId } = useAuth();
	const [userStatus, setUserStatus] = useState(null);
	const [orderData, setOrderData] = useState(null);
	const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);
	const transcriptionOrderId = useRef(null);
	const transkriptorSk = useRef(null);
	const transkriptorPk = useRef(null);

	const [duration, setDuration] = useState(0);
	const [date, setDate] = useState(null);
	const [meetingName, setMeetingname] = useState(null);
	const videoPlayerRef = useRef(null);
	const videoControlRef = useRef(null);
	const [orderId, setOrderId] = useState(oid);

	const [videoLoading, setVideoLoading] = useState(true);
	const [isBuffering, setIsBuffering] = useState(false);

	const [currentTime, setCurrentTime] = useState(0);

	console.log("currentTime", currentTime, played)

	const { showModal, modalData } = useModal();

	const undoRef = useRef(null);
	const redoRef = useRef(null);
	const videoRef = useRef(null);
	const playerWrapperRef = useRef(null);

	
	const [prevVolume, setPrevVolume] = useState(0.5);

	const [videoState, setVideoState] = useState({
		playing: true,
		muted: false,
		volume: 0.5,
		played: 0,
		seeking: false,
		Buffer: true,
		playbackRate: 1,
	});

	const playPauseHandler = () => {
		setVideoState({ ...videoState, playing: !videoState.playing });
	};


	useEffect(() => {
		getOrderInfo(true);
	}, [
		tid
	]);

	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);


	const rewindHandler = () => {
		//Rewinds the video player reducing 5

		videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10 < 0 ? 0 : videoPlayerRef.current.getCurrentTime() - 10);
	};

	const fastFowardHandler = () => {
		console.log("forward"
			, videoPlayerRef.current)
		//FastFowards the video player by adding 10
		videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10 > videoPlayerRef.current.getDuration() ? videoPlayerRef.current.getDuration() : videoPlayerRef.current.getCurrentTime() + 10);
	};

	const handleUndo = (e) => {
		console.log("undo");
		e.preventDefault();
		document.execCommand('undo', false, null);
	}

	function convertMsToFormattedDate(ms) {
		ms = parseInt(ms);
		const date = new Date(ms);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a 0-based value
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
	}

	const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};



	const onRename = async (tid, uid, name) => {
		setMeetingname(name);
		const response = await fetchDataPost(
			"https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
			{ tid: "#Meetingtor#" + tid, Tname: name, cid: "User#" + uid }
		);
		if (response)
			showToast(t("File renamed"), { type: "success" });
	};

	const shareLink = async () => {
		try {
			// Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
			let baseUrl = window.location.origin;

			let link = baseUrl + "/sharing/" + orderId;

			await navigator.clipboard.writeText(link);

			showToast(t("File link copied to clipboard"));

			return true;

		} catch (err) {
			console.error('Failed to copy text: ', err);
			return false;
		}
	};

	const videoComponentStyles = `
    	.video-container * {
      all: unset; /* This resets all inherited styles. Use 'initial' if you want to set properties to their initial values instead */
      /* Define any specific styles for your video component here */
    }
  	`;


	const getOrderInfo = async () => {
		try {
			var lastPath = `pk=${uid}&sk=${tid}`;
			if(oid){
				lastPath = `oid=${oid}`;
			}
			const res = await fetch(`https://k3gle01ol3.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-GetOrderContent?${lastPath}`);
			if (res.ok) {
				const result = await res.json();
				setOrderData(result?.meeting);
				if(!orderId || orderId === undefined){
					setOrderId(result?.meeting?.OrderID);	
				}
				if (result?.meeting?.TranskriptorOrderId && result?.meeting?.TranskriptorOrderId !== "Authorization Error") {
					transcriptionOrderId.current = result?.meeting?.TranskriptorOrderId;
				}

				if (result?.meeting?.TranskriptorSK) {
					transkriptorSk.current = result?.meeting?.TranskriptorSK.replace("#Transkription#", "");
				}

				if (result?.meeting?.PK) {
					transkriptorPk.current = result?.meeting?.PK.replace("User#", "");
				}
				setMeetingname(result?.meeting?.TempName);
				setDate(convertMsToFormattedDate(result?.meeting?.Date));
				setTranscriptionInProgress(false);
				return true;
			}
		}
		catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		shareLink();
	}, []);
	

	const initTranscriptModal = async (orderData) => {
		console.log("initTranscriptModal", orderData);
		
		if (transcriptionOrderId.current) {
			
				
		/*		 if (sk.includes("UploadTranskription#")) {
					setTimeout(() => { 
						initTranscriptModal(orderData);
					}, 2000);
					return;
				 } */
				if (transkriptorSk.current && transkriptorPk.current) {
					const sk = transkriptorSk.current;
					const pk = transkriptorPk.current;
					console.log("sk", sk);
					console.log("pk", pk);
					const url = `https://app.transkriptor.com/editor/${sk}/${pk}?uid=${hashedId}`
					const newTab = window.open(url, '_blank');
 
					// If opening a new tab fails (e.g., due to browser restrictions), redirect the current tab
					if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
					  window.location.href = url;
					}
				}

		} else {
			if (userStatus && userStatus === "Trial") {
				showModal("UPGRADE-TRANSCRIBE");
			} else {
				showModal("TRANSCRIPTION-LANGUAGE-MODAL", { startAndNavigateTranscription, orderData });
			}
		}

	};

	const startAndNavigateTranscription = async (orderData) => {
	
			const {sk, pk} = await startTranscriptionAttempt(orderData, true);
			if (sk) {
				initTranscriptModal(orderData);
			}
	};

	const handleLoadedMetadata = () => {
		const player = videoPlayerRef.current;
		if (!player) return;

		const duration = player.getDuration();

		setVideoLoading(false);
		setDuration(duration);
		setIsBuffering(false);
	};



	useEffect(() => {
		const handleCopy = (e) => {
			if (userStatus && userStatus === "Trial") {
				e.preventDefault();
				showModal("UPGRADE-DOWNLOAD");
			}
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy); // Cleanup the event listener on component unmount
		};
	}, [userStatus]);





	const startTranscriptionAttempt = async (orderData, fetchOrderInfo) => {
		let sk = null;
		let pk = null;
		try {
			setTranscriptionInProgress(true);
			const response = await fetch('https://h7y3edve59.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-InitiateTranscription', {
				method: 'POST',
				body: JSON.stringify({ "tid": orderData?.SK ?? "", "cid": orderData?.PK ?? "" })
			});
			if (!response.ok) {
				if (response.status === 406) {
					showModal("UPGRADE-TRANSCRIBE");
				} else {
					const data = await response.json();
					showToast(data.message, { type: "error" });
				}
			} else {
				const data = await response.json();
				console.log("data", data);
				sk = data.sk?.replace("#Transkription#", "");
				pk = data.pk?.replace("User#", "")
				
				
				if (fetchOrderInfo) {
				const res = await getOrderInfo();
				if (res){
					setTranscriptionInProgress(false);
					return {sk, pk};
				}} else {
					return {sk, pk};
				}
				
			}

		} catch (error) {
		}
		setTranscriptionInProgress(false);
		return {sk, pk};
	}

	const checkUser = async () => {
		const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
		if (response.SStatus === "Trial") {
			showModal("UPGRADE-DOWNLOAD");
		} else {
			// Create a new anchor element
			const link = document.createElement('a');
			link.href = orderData?.video_url;
			link.setAttribute(
				'download',
				meetingName + '.mp4',
			);

			// Append to the DOM
			document.body.appendChild(link);

			// Programmatically click the link to trigger the download
			link.click();

			// Remove the link from the DOM
			document.body.removeChild(link);
		}
	}

	const formatTime = (time) => {
		if (isNaN(time)) {
			return "00:00";
		}

		const date = new Date(time * 1000);
		const hours = date.getUTCHours();
		const minutes = date.getUTCMinutes();
		const seconds = date.getUTCSeconds().toString().padStart(2, "0");
		if (hours) {
			return `${hours}:${minutes}:${seconds}`;
		} else return `${minutes}:${seconds}`;
	};

	const progressHandler = (state) => {
		console.log('Progress event:', state);

		if (!seeking) {
			setVideoState({ ...videoState, ...state });
		}
	};

	const seekHandler = (e, value) => {
		console.log('Seeking to:', parseFloat(value) / 100);
		//setVideoState({ ...videoState, played: parseFloat(value) / 100 });
		videoPlayerRef.current.seekTo(value / 100);

	};

	const seekMouseUpHandler = (e, value) => {
		console.log('Seek released at:', value / 100);
		setVideoState({ ...videoState, seeking: false });
		videoPlayerRef.current.seekTo(value / 100);
	};

	const handleReplay = () => {
		videoPlayerRef.current.seekTo(0);
		setPlaying(true);
	};

	const volumeChangeHandler = (event, value) => {
		event && event.stopPropagation();
		const newVolume = parseFloat(value) / 100;
		console.log('Volume changed:', newVolume);
		setVideoState({
			...videoState,
			volume: newVolume,
			muted: Number(newVolume) === 0 ? true : false,
		})

	};

	const volumeSeekUpHandler = (event, value) => {

		event.stopPropagation();
		const newVolume = parseFloat(value) / 100;
		console.log('Volume released at:', newVolume);
		setVideoState({
			...videoState,
			volume: newVolume,
			muted: newVolume === 0 ? true : false,
		})
	};


	const muteHandler = () => {
		setVideoState({ ...videoState, muted: !videoState.muted });
	};


	useEffect(() => {
		const handleKeyPress = (event) => {
			if (modalData !== null) {
				return;
			}
			if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.code)) {
				event.preventDefault();
			}

			switch (event.code) {
				case "Space":
					if (!playerWrapperRef.current.contains(document.activeElement)) {
						if (document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA") {
						playPauseHandler();
						if(videoState.played === 1){
							handleReplay();
							setVideoState({ ...videoState, playing: true, played: 0, seeking: false, Buffer: true});
						}
						}
					}
					break;
				case "ArrowUp":
					{
						const volumeUp = Math.min(1, volume + 0.1);
						setVolume(volumeUp);
						volumeChangeHandler(null, volumeUp * 100);
						if (videoPlayerRef.current) {
							videoPlayerRef.current.getInternalPlayer().volume = volumeUp;
						}
					}
					break;
				case "ArrowDown":
					{
						const volumeDown = Math.max(0, volume - 0.1);
						setVolume(volumeDown);
						volumeChangeHandler(null, volumeDown * 100);
						if (videoPlayerRef.current) {
							videoPlayerRef.current.getInternalPlayer().volume = volumeDown;
						}
					}
					break;
				case "ArrowRight":
					fastFowardHandler();
					break;
				case "ArrowLeft":
					rewindHandler();
					break;
				default:
					break;
			}
		};

		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [playPauseHandler, volume, fastFowardHandler, rewindHandler, videoPlayerRef]);

	const isIOS = () => {
			return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		  };
		 
		  const toggleFullScreen = () => {
			if (isIOS()) {
			  let videoElement = videoPlayerRef.current.getInternalPlayer();
			  if (videoElement && videoElement.webkitEnterFullscreen) {
				videoElement.webkitEnterFullscreen();
			  }
			} else {
			  // For non-iOS devices
			  if (screenfull.isEnabled) {
				screenfull.toggle(playerWrapperRef.current);
			  }
			}
		}

	useEffect(() => {
		const changeHandler = () => {
			setIsFullscreen(screenfull.isFullscreen);
		};

		if (screenfull.isEnabled) {
			screenfull.on('change', changeHandler);
		}

		return () => {
			if (screenfull.isEnabled) {
				screenfull.off('change', changeHandler);
			}
		};
	}, []);

	useEffect(() => {
		let mouseMoveTimeout;
		const handleMouseMove = () => {
			clearTimeout(mouseMoveTimeout);
			playerWrapperRef.current.classList.remove('hide-cursor');

			mouseMoveTimeout = setTimeout(() => {
				playerWrapperRef.current.classList.add('hide-cursor');
			}, 3000);
		};

		const playerWrapper = playerWrapperRef.current;
		playerWrapper.addEventListener('mousemove', handleMouseMove);

		return () => {
			playerWrapper.removeEventListener('mousemove', handleMouseMove);
			clearTimeout(mouseMoveTimeout);
		};
	}, []);

	useEffect(() => {
		const handleVideoClick = (event) => {
			if (controlRef.current && controlRef.current.contains(event.target)) {
				return;
			}
			playPauseHandler();
		};

		const playerWrapper = playerWrapperRef.current;
		playerWrapper.addEventListener('click', handleVideoClick);

		return () => {
			playerWrapper.removeEventListener('click', handleVideoClick);
		};
	}, [playPauseHandler]);


	return (
		<div className="row justify-content-center ">
			<div className="col-12 video-full ">
				<div className="card card-padding overflow-visible">
					<div className="row align-items-center">
						<div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
							<h2 class="small-title regular mb-2">
								<span class="me-2 burger-web-view">
									<img
										src={fileMark}
										class="img-fluid"
										height="auto"
										width="28px"
										alt="img"
									/>
								</span>
								{!isMobileDevice() &&
									<span class="me-2 burger-mobile-view" onClick={() => handleOpenSidebar()}>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

									</span>}
								<div className="filename-container">
									<span className="filename">{meetingName}</span>
									<Link
										onClick={() =>
											showModal(
												"RENAME",
												{ name: meetingName, tid: tid, uid: uid },
												{ onRename }
											)
										}
										className="web-view"
									>
										<span
											class="icons-hover"
											data-bs-toggle="tooltip"
											data-bs-placement="bottom"
											title={t("Rename")}
											data-bs-original-title="Rename"
										>
											<img src={rename} class="img-fluid" alt="img" />
										</span>
									</Link>
								</div>
							</h2>
							<p class="d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view">
								<span class="me-1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="14"
										height="16"
										viewBox="0 0 19.2 18"
									>
										<g id="date-line" transform="translate(-2 -2)">
											<path
												id="Path_135"
												data-name="Path 135"
												d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
												transform="translate(0 -1.6)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_136"
												data-name="Path 136"
												d="M8,14H9.2v1.2H8Z"
												transform="translate(-2.4 -4.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_137"
												data-name="Path 137"
												d="M14,14h1.2v1.2H14Z"
												transform="translate(-4.8 -4.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_138"
												data-name="Path 138"
												d="M20,14h1.2v1.2H20Z"
												transform="translate(-7.2 -4.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_139"
												data-name="Path 139"
												d="M26,14h1.2v1.2H26Z"
												transform="translate(-9.6 -4.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_140"
												data-name="Path 140"
												d="M8,19H9.2v1.2H8Z"
												transform="translate(-2.4 -6.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_141"
												data-name="Path 141"
												d="M14,19h1.2v1.2H14Z"
												transform="translate(-4.8 -6.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_142"
												data-name="Path 142"
												d="M20,19h1.2v1.2H20Z"
												transform="translate(-7.2 -6.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_143"
												data-name="Path 143"
												d="M26,19h1.2v1.2H26Z"
												transform="translate(-9.6 -6.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_144"
												data-name="Path 144"
												d="M8,24H9.2v1.2H8Z"
												transform="translate(-2.4 -8.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_145"
												data-name="Path 145"
												d="M14,24h1.2v1.2H14Z"
												transform="translate(-4.8 -8.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_146"
												data-name="Path 146"
												d="M20,24h1.2v1.2H20Z"
												transform="translate(-7.2 -8.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_147"
												data-name="Path 147"
												d="M26,24h1.2v1.2H26Z"
												transform="translate(-9.6 -8.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_148"
												data-name="Path 148"
												d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
												transform="translate(-2.8)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_149"
												data-name="Path 149"
												d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
												transform="translate(-9.2)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_150"
												data-name="Path 150"
												d="M13,6h6V7.2H13Z"
												transform="translate(-4.4 -1.6)"
												fill="#6b6c6d"
											/>
										</g>
									</svg>
								</span>
								{date}
							</p>
							<p class="d-sm-inline-block d-block ft-14 mb-1 web-view">
								<span class="me-1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="14"
										height="14"
										viewBox="0 0 16.875 16.875"
									>
										<g id="clock" transform="translate(-1.125 -1.125)">
											<path
												id="Path_151"
												data-name="Path 151"
												d="M17.966,6.715H16.841v6.261l4.064,1.932.483-1.016-3.423-1.627Z"
												transform="translate(-7.858 -2.795)"
												fill="#6b6c6d"
											/>
											<path
												id="Path_152"
												data-name="Path 152"
												d="M9.563,1.125A8.438,8.438,0,1,0,18,9.563,8.437,8.437,0,0,0,9.563,1.125Zm0,15.75a7.313,7.313,0,1,1,7.313-7.312A7.313,7.313,0,0,1,9.563,16.875Z"
												fill="#6b6c6d"
											/>
										</g>
									</svg>
								</span>
								{formatTime(duration)}
							</p>
						</div>
						<div class="col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3 a1">
						</div>
						<div class="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate a2">
							<div class="file-action-icon-flex mb-3">
								<Link ref={undoRef} onClick={handleUndo} style={{ display: "none" }} className="hover-effect">
									<span
										class="file-action-list"
										data-bs-toggle="tooltip"
										data-bs-placement="bottom"
										title={t("Undo")}
									>
										<img src={undo} class="img-fluid" alt="img" />
									</span>
								</Link>
								<Link ref={redoRef} style={{ display: "none" }} className="hover-effect">
									<span
										class="file-action-list"
										data-bs-toggle="tooltip"
										data-bs-placement="bottom"
										title={t("Redo")}
										ref={redoRef}
									>
										<img src={redo} class="img-fluid" alt="img" />
									</span>
								</Link>
								<Link
									onClick={(e) => {
										e.preventDefault();
										checkUser()
									}}
									className="hover-effect"
								>
									<span
										class="file-action-list theme-color"
									>
										<span className="hover-span">
											{t("Download")}
										</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="21"
											height="19.34"
											viewBox="0 0 21 19.34"
										>
											<g
												id="Group_46"
												data-name="Group 46"
												transform="translate(-0.16)"
											>
												<rect
													id="Rectangle_36"
													data-name="Rectangle 36"
													width="21"
													height="15"
													rx="3"
													transform="translate(21.16 15.34) rotate(180)"
													fill="#fff"
												/>
												<rect
													id="Rectangle_37"
													data-name="Rectangle 37"
													width="21"
													height="5"
													rx="2.5"
													transform="translate(21.16 17.34) rotate(180)"
													fill="currentColor"
												/>
												<rect
													id="Rectangle_38"
													data-name="Rectangle 38"
													width="21"
													height="5"
													rx="2.5"
													transform="translate(21.16 19.34) rotate(180)"
													fill="#fff"
												/>
												<g
													id="arrow-small-down"
													transform="translate(5.402)"
												>
													<path
														id="Path_163"
														data-name="Path 163"
														d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
														transform="translate(-11.25 -15)"
														fill="currentColor"
													/>
												</g>
											</g>
										</svg>
									</span>
								</Link>
								<Link class="share_btn hover-effect" onClick={(e) => {
									e.preventDefault();
									shareLink()
								}}>
									<span
										class="file-action-list"
									>
										<span className="hover-span">
											{t("Share")}
										</span>
										<img src={share} class="img-fluid" alt="img" />
									</span>
								</Link>
							</div>
						</div>
					</div>
					<hr class="mt-0" />
					<div >
						<ul
							class="nav nav-pills btn-pills mb-3 notranslate "
							id="pills-tab"
							role="tablist"
						>
							<li class="nav-item" role="presentation">
								<button
									class="nav-link active"
									id="pills-traskript-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-traskript"
									type="button"
									role="tab"
									aria-controls="pills-traskript"
									aria-selected="true"
								>
									<span class="btn-tab-icon" >
										<img
											src={tabMeetingtorBlue}
											class="img-fluid btn-reg-icon"
											alt="img"
										/>
										<img
											src={logoMeetingtor}
											class="img-fluid btn-active-icon"
											alt="img"
										/>
									</span>
								</button>
							</li>
							<button
								class="nav-link"
								onClick={() => { initTranscriptModal(orderData) }}
								style={{ borderColor: "#007bff", backgroundColor: "white" }}
							>
								<span class="btn-tab-icon" >
									<img
										src={logoTranskriptor}
										class="img-fluid btn-reg-icon"
										alt="img"
									/>
									<img
										src={tabTranskriptorWhite}
										class="img-fluid btn-active-icon"
										alt="img"
									/>
								</span>
							</button>
						</ul>
						<div class="tab-content" id="pills-tabContent" >
							<div
								class="tab-pane fade show active"
								id="pills-traskript"
								role="tabpanel"
								aria-labelledby="pills-traskript-tab"
							>
								{(videoLoading || transcriptionInProgress) &&
									<div className=' card-padding'>
										<br />
										<div className='d-flex justify-content-center text-center'>
											<span class="spinner-border text-primary"></span>
										</div>
									</div>
								}
								{
									<div class="row video-outside" >
										<div className="text-center ">
											<div className={`video-container ${isFullscreen ? 'fullscreen-mode' : ''}`} style={{ width: '60%', margin: '0 auto' }}>
												<Container justify="center">
													<div ref={playerWrapperRef} className={`player__wrapper`}>
													{isBuffering && (
													  <div className="loading-indicator">
														<CircularProgress />
													  </div>
													)}
														<ReactPlayer
															ref={videoPlayerRef}
															url={orderData?.video_url}
															playing={videoState.playing}
															seeking={videoState.seeking}
															volume={videoState.volume}
															muted={videoState.muted}
															playbackRate={playbackRate}
															progressInterval={100}
															onProgress={progressHandler}
															playsinline={true}
															width={"100%"}
															height={"100%"}
															controls={false}
															className='react-player' id='video-src' style={{ display: videoLoading ? 'none' : null, borderRadius: '25px' }}
															onReady={handleLoadedMetadata}
															onContextMenu={(e) => e.preventDefault()}
															config={{
																attributes: {
																	disablePictureInPicture: true,
																	preload: "auto"
																}
															}}
															onBuffer={() => setIsBuffering(true)}
														/>
														{
															!videoLoading &&
															<VideoControls
																playing={videoState.playing}
																onPlayPause={playPauseHandler}
																volume={videoState.volume}
																onVolumeSeekUp={volumeSeekUpHandler}
																onVolumeChange={volumeChangeHandler}
																muted={videoState.muted}
																onRewind={rewindHandler}
																onForward={fastFowardHandler}
																currentDuration={duration}
																loaded={duration * loaded}
																played={videoState.played}
																currentTime={duration * videoState.played}
																onSeek={seekHandler}
																onSeekMouseUp={seekMouseUpHandler}
																onReplay={handleReplay}
																mute={muted}
																onMute={muteHandler}
																controlRef={controlRef}
																onToggleFullscreen={toggleFullScreen}
																isFullscreen={isFullscreen}
																playbackRate={playbackRate}
																setPlaybackRate={setPlaybackRate}
																playerbackRate={videoState.playerbackRate}
																ref={videoControlRef}
																videoPlayerRef={videoPlayerRef}
															/>
														}
													</div>
												</Container>
											</div>
										</div>
									</div>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MeetingView;
