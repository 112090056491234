import React, { useState, useEffect, useRef } from 'react';
import { Slider, Button, Tooltip, Popover, Grid, IconButton, Menu, MenuItem, LinearProgress, Typography, Avatar } from "@mui/material";
import { FastForward, FastRewind, Pause, PlayArrow, SkipNext, VolumeUp, VolumeOff, Replay30, Forward30, Forward10, Replay10, Replay } from "@mui/icons-material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { makeStyles, withStyles } from "@mui/styles";
import SpeedIcon from '@mui/icons-material/Speed';
import { t } from "../../components/common/translation";
import screenfull from 'screenfull';
import { useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


import "./VideoControls.css";

const useStyles = makeStyles({
  volumeControlContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0px',
  },
  volumeSlider: {
    width: "100px",
    color: "white",
    marginLeft: "0px",
  },

  bottomIcons: {
    color: "#999",
    padding: "12px 8px",


    "&:hover": {
      color: "#fff",
    },
  },
  iconButton: {
    color: "white",
    padding: "0px",
    "&:hover": {
      color: "#fff",
    },
    Popover: {
      position: "absolute",
      padding: "0px",
    },
    fontSize: "2rem",
    transition: "all ease 0.3s !important",
    transformOrigin: "center",
    width: "30px",
    marginRight: "5px",
    marginLeft: "5px",
    cursor: "pointer",
  },
  FullscreenIcon: {
    color: "white",
    padding: "0px",
    "&:hover": {
      color: "#fff",
    },
    fontSize: "2rem",
    transition: "all ease 0.3s !important",
    transformOrigin: "center",
    width: "30px",
    marginLeft: "-15px",
    marginRight: "20px !important",
  },
  FullscreenExitIcon: {
    color: "white",
    padding: "0px",
    "&:hover": {
      color: "#fff",
    },
    fontSize: "2rem",
    transition: "all ease 0.3s !important",
    transformOrigin: "center",
    width: "30px",
    marginLeft: "-15px",
    marginRight: "30px  !important",
  },
  xlargeIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '5rem',
      color: "white",
      padding: "0px",
      "&:hover": {
        color: "#fff",
      },
      transition: "all ease 0.3s !important",
      transformOrigin: "center",
    }
  },
  largeIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
      color: "white",
      padding: "0px",
      "&:hover": {
        color: "#fff",
      },
      transition: "all ease 0.3s !important",
      transformOrigin: "center",
    }
  },
  Popover: {
    position: "relative",
  },
  fontVariantNumeric: {
    marginLeft: "15px",
    color: "white !important",
  },
  "@media (min-width: 1024px)": {
    padding: "12px",
    "& .MuiSvgIcon-root": {
      fontSize: "2.4rem",
    },
    iconButton: {
      fontSize: "2rem",
    },
    FullscreenIcon: {
      fontSize: "2rem",
    },
    FullscreenExitIcon: {
      fontSize: "2rem",
    },
    PrettoSlider: {
      padding: "12px !important",
    },
  },
  "@media (max-width: 760px)": {
    iconButton: {
      fontSize: "1.5rem",
    },
    IconButton: {
      fontSize: "1.5rem",
    },
    FullscreenIcon: {
      fontSize: "1.5rem",
      marginLeft: "-5px",
      marginRight: "10px !important",
    },
    FullscreenExitIcon: {
      fontSize: "1.5rem",
    },
    padding: "8px",
    PrettoSlider: {
      padding: "8px",
    },
    slider_container: {
      padding: "4px",
    },
  },
  "@media (max-width: 760px)"
    : {
    volumeSlider: {
      width: "100px",
      color: "white",
      marginLeft: "0px",
    },
    controlVisibility: {
      '@media (max-width: 640px)': {
        display: 'none',
      },
      iconButton: {
        padding: "0px",
      },
      FullscreenIcon: {
        fontSize: "1.5rem",
        marginLeft: "-5px",
        marginRight: "10px !important",
      },
      FullscreenExitIcon: {
        fontSize: "1.5rem",
      },
    },
    fontVariantNumeric: {
      marginLeft: "0px !important",
    },
  },
  "@media (max-width: 540px)": {
    iconButton: {
      fontSize: "1.4rem",
    },
    IconButton: {
      fontSize: "1.4rem",
    },
    FullscreenIcon: {
      fontSize: "1.2rem",
      marginLeft: "-18px !important",
      marginRight: "10px !important",
    },
    FullscreenExitIcon: {
      fontSize: "1.4rem",
    },
    PrettoSlider: {
      padding: "8px",
    },
    tabularNums: {
      marginLeft: "0px !important",
      padding: "0px !important",
    },
    PlayArrowIcon: {
      marginRight: "0px",
    }
  },
  "@media (max-width: 360px)": {
    iconButton: {
      fontSize: "1.4rem",
    },
    IconButton: {
      fontSize: "1.4rem",
    },
    FullscreenIcon: {
      fontSize: "1.4rem",
      marginLeft: "-10px !important",
      marginRight: "8px !important",
    },
    FullscreenExitIcon: {
      fontSize: "1.4rem",
    },
    padding: "6px",
  },
  PrettoSlider: {
    padding: "6px !important",
  },
});

const formatTime = (time) => {

  console.log("time", time);
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    return `${hours}:${minutes}:${seconds}`;
  } else return `${minutes}:${seconds}`;
};

const PrettoSlider = withStyles({
  root: {
    color: 'var(--slider-track-color, #fff)',
    height: 8,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);


function VideoControls({ playing, onPlayPause, onReplay, onRewind, onForward, played, onSeek, onSeekMouseUp, onVolumeSeekUp, volume, muted, onMute, currentTime, currentDuration, isFullscreen, playbackRate,
  setPlaybackRate, playerbackRate, onVolumeChange, onToggleFullscreen, controlRef, videoPlayerRef, }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  console.log("playeddd", played);
  const matches = useMediaQuery('(min-width:760px)');
  const [showControls, setShowControls] = useState(false);
  const controlsVisibilityTimeout = useRef(null);
  const [isMouseOver, setIsMouseOver] = useState(true);
  const bottomContainerRef = useRef(null);
  const zoomContainer = useRef(null);
  const duration = formatTime(currentDuration);
  const time = formatTime(currentTime);
  const videoEnded = Math.floor(currentTime) >= Math.floor(currentDuration);

  const handleReplay = () => {
    if (onReplay) {
      onReplay();
    }
  };

  const handlePlayPauseClick = () => {
    onPlayPause();
    setShowControls(true);
    clearTimeout(controlsVisibilityTimeout.current);
    controlsVisibilityTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 300);
  };

  return (
    <div className="control_Container" onClick={handlePlayPauseClick} ref={controlRef} style={isFullscreen ? {marginBottom:'0px'}: {}}>
      <div className="mid__container">
        {videoEnded ? (
          <IconButton
            onClick={() => onReplay()}
            className={matches ? classes.xlargeIcon : classes.largeIcon}
          >
            <Replay />
          </IconButton>
        ) : (
          <span style={{ opacity: showControls ? 1 : 0, transition: 'opacity 0.3s ease' }}>
            {playing ? (
              <IconButton
                onClick={() => handlePlayPauseClick()}
                className={matches ? classes.xlargeIcon : classes.largeIcon}
              >
                <Pause />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => handlePlayPauseClick()}
                className={matches ? classes.xlargeIcon : classes.largeIcon}
              >
                <PlayArrow />
              </IconButton>
            )}
          </span>
        )}
      </div>
      <div className={`bottom__container ${!isMouseOver ? 'hide-controls' : ''}`} ref={bottomContainerRef} onClick={(e) => e.stopPropagation()} >
        <div className="slider__container" >
          {!open && (
            <PrettoSlider
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              onChangeCommitted={onSeekMouseUp}
              size='small'
            />
          )}
        </div>
        {/*  <LinearProgress style={{width:"-webkit-fill-available"}} variant="buffer" value={100*currentTime/currentDuration} valueBuffer={100*loaded/currentDuration} /> */}
        <div className="control__box" >
          <div className="inner__controls" onClick={(e) => e.stopPropagation()}  >
            {
              videoEnded ? (
                <IconButton onClick={handleReplay} className={classes.iconButton}>
                  <Replay fontSize="inherit" />
                </IconButton>
              ) :
                playing ? (
                  <Pause onClick={onPlayPause} className={classes.iconButton} />
                ) : (

                  <PlayArrow onClick={onPlayPause} className={classes.iconButton} />
                )}
            <Replay10 className={`${classes.iconButton} ${classes.controlVisibility}`} onClick={onRewind} />
            <Forward10 className={`${classes.iconButton} ${classes.controlVisibility}`} onClick={onForward} />
            <div style={{
              fontVariantNumeric: "tabular-nums",
              color: "white",
            }} className={`${classes.timeBox} timeBox-white-color`}>{time}/{duration}</div>
            <IconButton onClick={onMute} >
              {muted ? <VolumeOff className={classes.iconButton} /> : <VolumeUp className={classes.iconButton} />}
            </IconButton>
            <Slider
              className={`${classes.volumeSlider} zoomStyle`}
              onChange={onVolumeChange}
              onClick={(e) => e.stopPropagation()}
              value={muted ? 0 : volume * 100}
              onChangeCommitted={onVolumeSeekUp}
              size='small'
            />
          </div>
          <div className='d-flex justify-content-center'>
            <div ref={zoomContainer}>
              <div class="dropdown playspeed-dropdown">
                <Button variant='text'>
                  <Typography>{playerbackRate}<IconButton><SpeedIcon className={classes.iconButton} style={{ color: "white" }} /></IconButton></Typography>
                </Button>
                <div
                  className="dropdown-content"
                  style={{ width: "fit-content", borderRadius: "5px", padding: "5px", backgroundColor: "black", color: "white", border: "1px solid white" }}
                >
                  <MenuItem sx={{ color: playbackRate == 2 && "red" }} onClick={(e) => { e.stopPropagation(); setPlaybackRate(2); }}>2</MenuItem>
                  <MenuItem sx={{ color: playbackRate == 1.5 && "red" }} onClick={(e) => { e.stopPropagation(); setPlaybackRate(1.5); }}>1.5</MenuItem>
                  <MenuItem sx={{ color: playbackRate == 1 && "red" }} onClick={(e) => { e.stopPropagation(); setPlaybackRate(1);; }}>1</MenuItem>
                  <MenuItem sx={{ color: playbackRate == 0.5 && "red" }} onClick={(e) => { e.stopPropagation(); setPlaybackRate(0.5); }}>0.5</MenuItem>
                </div>
              </div>
            </div>
            <IconButton onClick={onToggleFullscreen} aria-label="toggle fullscreen" >
              {isFullscreen ? <FullscreenExitIcon className={classes.FullscreenIcon} /> : <FullscreenIcon className={classes.FullscreenIcon} />}
            </IconButton>
          </div>
        </div>
      </div>
    </div >
  )
}

export default VideoControls;


