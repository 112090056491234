import React, { useEffect, useState } from 'react';
import { useApiData } from '../../hooks/useApiData';
import { useAuth } from '../../Auth/AuthContext';

import addTeamSvg from '../../assets/img/icon/add-team.svg';
import upgradeSvg from '../../assets/img/icon/upgrade.svg';

import { useNavigate, Link } from 'react-router-dom';

import { useModal } from '../../components/common/Modal/ModalContext'
import { fetchData, fetchDataPost } from '../../hooks/apiUtils';
import WorkspaceItem from './WorkspaceItem';
import { showToast } from '../../components/common/toast';
import { t } from '../../components/common/translation';

function Workspaces() {
	const { hashedId, userData } = useAuth();
	const navigate = useNavigate();
	const { showModal } = useModal();

	const [isLoading, setIsLoading] = useState(false);
	const [allWorkspaces, setAllWorkspaces] = useState([]);
	const [WorkspacesToShow, setWorkspacesToShow] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState("");
	const [userStatus, setUserStatus] = useState(null);

	const WorkspacesPerPage = 10;
	var startIndex = 0;
	var endIndex = 0;

	const showWorkspaces = async () => {
		setIsLoading(true);
		const response = await fetchData("https://5eozpazgv4.execute-api.eu-central-1.amazonaws.com/default/Get-Teams?HashedId=" + hashedId);
		setAllWorkspaces(response);
		console.log(response);
		console.log("kek")
		setIsLoading(false);
	};
	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);

	useEffect(() => {
		showWorkspaces();
	}, [hashedId]);
	useEffect(() => {
		// Calculate the indexes of files to be displayed on the current page
		startIndex = (currentPage - 1) * WorkspacesPerPage;
		endIndex = startIndex + WorkspacesPerPage;

		// Update the files to show based on the new indexes
		setWorkspacesToShow(allWorkspaces.slice(startIndex, endIndex));

	}, [allWorkspaces, currentPage]);
	const onCreateWorkspace = async (uid, workspaceName) => {
		setIsLoading(true);
		const response = await fetchDataPost("https://0n4xaw0876.execute-api.eu-central-1.amazonaws.com/default/Create-Team",
			{ UserHashedId: uid, TeamName: workspaceName });
		showWorkspaces();
		showToast(t("Folder created"));
	};
	const handleDelete = async (tid, uid) => {
		setIsLoading(true);
		console.log(tid, uid)
		const response = await fetch("https://v5ls3q1wp7.execute-api.eu-central-1.amazonaws.com/Delete-Team?TeamId=" + tid);
		showWorkspaces();
		showToast(t("Team deleted"));
	};
	const handleRename = async (tid, uid, name) => {
		setIsLoading(true);

		const response = await fetchDataPost("https://5j5dwdqmo2.execute-api.eu-central-1.amazonaws.com/default/Rename-Team",
			{ TeamId: tid, NewName: name });
		showWorkspaces();
		showToast(t("Team renamed"));
	};


	const handleInputChange = (e) => {
		setSearchQuery(e.target.value);
		console.log(e.target.value);
		filterWorkspaces(e.target.value);

	}

	const filterWorkspaces = (query) => {
		const filtered = allWorkspaces.filter(workspace =>
			workspace?.TeamName.toLowerCase().includes(query.toLowerCase())
		);
		startIndex = (currentPage - 1) * WorkspacesPerPage;
		endIndex = startIndex + WorkspacesPerPage;
		setWorkspacesToShow(filtered.slice(startIndex, endIndex));
	}
	const openRemoveDialog = async (email, tid) => {
		showModal("REMOVE-ENTERPRISE-MEMBER", { email, tid }, { onRemoveMember: handleRemoveMember })
	}
	const handleRemoveMember = async (email, tid) => {
		showToast(t("Member will be removed soon"), { type: "info" });
		fetch(
			"https://pwiuw9f0y5.execute-api.eu-central-1.amazonaws.com/Delete-Member-From-Team?TeamId=" +
			tid + "&DeletedMemberEmail=" + email)
			.then(function () {
				showWorkspaces();
				showToast(t("Member removed"), { type: "success" });
			});
	}

	const openCreateWorkspaceModal = () => {
		if (userStatus && userStatus === "Trial") {
			showModal("UPGRADE-WORKSPACE");
		} else {
			showModal('CREATE-WORKSPACE', { uid: hashedId }, { onCreateWorkspace })
		}
	}
	return (
		<>
			<div className='row'>
				<div className='col-lg-8 col-12'>
					<div className='d-lg-flex d-block justify-content-between align-items-center'>
						<h2 class="card-title mb-lg-4 mb-2">{t("Workspaces")}</h2>
						<div class="mb-4 table-serach input-icon-div d-lg-block d-none ms-auto">
							<input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={searchQuery} onChange={handleInputChange} />
							<span class="input-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
									<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-8 card-margin order-lg-1 order-2">
					<div class="d-lg-none d-block text-end mb-2">

						<button class="btn theme-btn mb-1" onClick={openCreateWorkspaceModal}>
							<span class="btn-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
									<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
								</svg>
							</span>{t("Create").toUpperCase()}
						</button>
					</div>
					<div className='card card-padding h-100'>
						<div className={`d-flex justify-content-center text-center ${isLoading ? "" : "d-none"}`}>
							<div class="spinner-border text-primary" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
						{WorkspacesToShow.length === 0 && !isLoading ? (
							<>
								<div class="col-12 card-margin">
									<div class="card card-padding h-100" onClick={openCreateWorkspaceModal}>
										<div class="text-center">
											<p className='m-2'>{t("No workspace available, please create workspace")}</p>
											<img src={addTeamSvg} class="img-fluid mb-3" alt="icon" /><br></br>
											<p class="ft-18-title mb-2">{t("Create New Workspace")}</p>
											<p>{t("With workspaces, you can share meetings and folders with members. Create a workspace to collaborate with your friends.")}</p>
										</div>
										<div class="text-center">
											<button class="btn theme-btn" >
												<span class="btn-icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
														<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
														<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
													</svg>
												</span>{t("Create").toUpperCase()}
											</button>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className=''>
									<table className='table'>
										<tbody>
											{WorkspacesToShow.map((workspace, index) => (
												<WorkspaceItem
													key={index}
													workspace={workspace}
													uid={hashedId}
													onDelete={handleDelete}
													onRename={handleRename}
													openRemoveDialog={openRemoveDialog}
												/>
											))}
										</tbody>
									</table>
								</div>
								<nav aria-label="..." className={`${WorkspacesToShow.length < WorkspacesPerPage ? 'd-none' : ''}`} >
									<ul className='pagination pagination-sm justify-content-end'>
										<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
											<Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
												<span className="fa fa-angle-left"></span>
											</Link>
										</li>

										{/* Show previous page if it's not the first page */}
										{currentPage > 1 && (
											<li className="page-item">
												<Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
													{currentPage - 1}
												</Link>
											</li>
										)}

										{/* Current Page */}
										<li className="page-item active">
											<Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
												{currentPage}
											</Link>
										</li>

										{/* Show next page if it's not the last page */}
										{currentPage < Math.ceil(allWorkspaces.length / WorkspacesPerPage) && (
											<li className="page-item">
												<Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
													{currentPage + 1}
												</Link>
											</li>
										)}

										<li className={`page-item ${currentPage === Math.ceil(allWorkspaces.length / WorkspacesPerPage) ? 'disabled' : ''}`}>
											<Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(allWorkspaces.length / WorkspacesPerPage)))}>
												<span className="fa fa-angle-right"></span>
											</Link>
										</li>
									</ul>
								</nav>
							</>

						)}

					</div>
				</div>
				<div class="col-lg-4 order-lg-2 order-1 d-lg-block d-none">
					<div class="row h-100">

						<div class="col-12 card-margin">
							<div class="card card-padding h-100 flex-center card-hover" onClick={openCreateWorkspaceModal}>
								<div class="text-center">
									<img src={addTeamSvg} class="img-fluid mb-3" alt="icon" /><br></br>
									<p class="ft-18-title mb-2">{t("Create New Workspace")}</p>
									<p>{t("With workspaces, you can share meetings and folders with members. Create a workspace to collaborate with your friends.")}</p>
								</div>

							</div>
						</div>
						<div class="col-12 card-margin">
							<div class="card card-padding h-100 flex-center card-hover" onClick={() => navigate("/payment")}>
								<div class="text-center">
									<img src={upgradeSvg} class="img-fluid mb-3" alt="icon" /><br></br>
									<p class="ft-18-title mb-2">{t("Upgrade")}
										<span class="ms-1">

										</span>
									</p>
									<p>{t("Upgrade your account to join more meetings and get the transcription of meetings.")}</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className='row'>
				<div class="d-lg-none d-block text-end mb-2" style={{ marginTop: '40px' }}>
					<div class="card card-padding h-100 card-hover" onClick={() => navigate("/payment")}>
						<div class="text-center">
							<img src={upgradeSvg} class="img-fluid mb-3" alt="icon" /><br></br>
							<p class="ft-18-title mb-2">{t("Upgrade")}
								<span class="ms-1">

								</span>
							</p>
							<p>{t("Upgrade your account to join more meetings and get the transcription of meetings.")}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Workspaces;
